import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../components-v2/shadcn/dialog'
import { notEmpty } from '../../utils/formHelpers'
import { useState } from 'react'
import { Input } from '../../components-v2/shadcn/input'
import Button from '../../components-v2/Button'
import { useSelector } from 'react-redux'
import { changePassword, requestANewUserType } from '../../services/auth'
import { toast } from 'sonner'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../components-v2/shadcn/select'
import { cn } from '../../utils/cn'

type DialogProps = {
  cb?: () => void
  trigger?: React.ReactNode
}

export default function RequestUserTypeDialog(props: DialogProps) {
  const { trigger } = props
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="max-h-screen max-w-[400px] overflow-y-auto px-5 py-5"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Content setOpen={setOpen} {...props} />
      </DialogContent>
    </Dialog>
  )
}

const schema = z.object({
  userType: z.string().pipe(notEmpty),
})

type SchemaType = z.infer<typeof schema>

const roles = [
  {
    name: 'Opinion Maker',
    value: 'Opinion Maker',
    description:
      'Start reading and sharing without identity verification and enjoy maximum fun and community feeling. Opinion makers can make posts of type Opinion, Rumor and Humor.',
  },
  {
    name: 'Citizen',
    value: 'Citizen',
    description:
      'Citizens can make posts of type Research, Opinion, Rumor and Humor. They can also contrast and counterbalance existing posts, which makes them a cornerstone of our community. We’d like to know who you are so there might be some follow-up questions. Politicians and policy makers are typically not approved in this category.',
  },
  {
    name: 'Professional',
    value: 'Professional',
    description:
      'Professionals can make all type of posts (including Facts). They can also contrast and counterbalance existing posts. Whether already working in the media industry or not, Professionals commit to journalism standards to ensure the quality of Facts, Research and other content they post. We’d like to know who you are so there might be some follow-up questions.',
  },
]

function Content({ cb, setOpen }: DialogProps & { setOpen: any }) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {},
  })

  const userType = watch('userType')

  const currentUser = useSelector((state: any) => state.login.user)

  let roleOptions: any[] = roles.map((x) => {
    if (x.name === currentUser.roles.name) {
      return { ...x, current: true }
    } else {
      return x
    }
  })

  if (!roleOptions.find((x) => x.current === true)) {
    roleOptions.push({
      name: currentUser.roles.name,
      value: currentUser.roles.name,
      current: true,
    })
  }

  return (
    <div>
      <form className="mx-auto flex max-w-[450px] flex-col">
        <h1 className="mt-5 text-2xl font-bold">Request a new user type</h1>

        <div className="mt-6">
          {/* <label className="text-[14px] font-bold">Requesting user Type</label> */}

          <Controller
            control={control}
            name="userType"
            render={({ field: { onChange, value } }) => {
              return (
                // <Select onValueChange={onChange} value={value}>
                //   <SelectTrigger className="w-full">
                //     <SelectValue placeholder="Select a user type" />
                //   </SelectTrigger>
                //   <SelectContent>
                //     {roleOptions.map((x: any) => {
                //       return (
                //         <SelectItem
                //           key={x.value}
                //           value={x.value}
                //           disabled={x?.current}
                //           className="flex items-center"
                //         >
                //           {x.name}{' '}
                //           {x?.current && (
                //             <span className="text-[14px] italic">
                //               (Your current user type)
                //             </span>
                //           )}
                //         </SelectItem>
                //       )
                //     })}
                //   </SelectContent>
                // </Select>

                <div className="flex flex-col gap-3">
                  {roleOptions.map((x) => {
                    return (
                      <div
                        key={x.value}
                        className={cn(
                          'cursor-pointer rounded-md border border-line p-5 transition-all hover:bg-gray-200',
                          {
                            'pointer-events-none bg-primary text-white':
                              x?.current,
                          },
                          {
                            ' bg-gray-200': x.value === value,
                          }
                        )}
                        onClick={() => {
                          onChange(x.value)
                          // onSelectItem(x.value)
                          // setOpen && setOpen(false)
                        }}
                      >
                        <h2 className="text-xl font-medium flex items-center gap-1">
                          {x.name}{' '}
                          {x?.current && (
                            <span className="text-sm italic font-normal text-border">
                              Your current user type
                            </span>
                          )}
                        </h2>
                        <p className="mt-1 text-sm">{x.description}</p>
                      </div>
                    )
                  })}
                </div>
              )
            }}
          />

          {errors?.userType && (
            <p className="text-[14px] font-semibold text-red-500">
              {errors?.userType.message}
            </p>
          )}
        </div>

        <div className="mt-5 flex items-center gap-2 justify-end">
          <span className="text-[14px] text-gray-600 italic">{userType}</span>
          <Button
            // type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit(async (values) => {
              return requestANewUserType(values.userType)
                .then((data) => {
                  toast.success('Request successfully sent.')
                  setOpen(false)
                })
                .catch((err) => {
                  toast.error(
                    err?.response?.data?.message || 'Something went wrong'
                  )
                })
            })}
          >
            Send Request
          </Button>
        </div>
      </form>
    </div>
  )
}
