import React, { useState } from 'react'
import { ChevronDown } from 'lucide-react'
import DropDownSVG from '../../../../assets/icons/drop-down.svg'
import {
  impressionsDimensionOptions,
  periodDimensionOptions,
  sortingDimensionOptions,
  userDimensionOptions,
} from '../helpers'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../../components-v2/shadcn/popover'
import { cn } from '../../../../utils/cn'

type Props = {
  setUserDimension: (type: string) => void
  userDimension: string

  setPeriodDimension: (type: string) => void
  periodDimension: string

  setImpressionsDimension: (type: string) => void
  impressionsDimension: string

  setSortingDimension: (type: string) => void
  sortingDimension: string
}

export default function TimeFilters({
  impressionsDimension,
  setImpressionsDimension,
  periodDimension,
  setPeriodDimension,
  sortingDimension,
  setSortingDimension,
  userDimension,
  setUserDimension,
}: Props) {
  // console.log('🚀 ~ userDimension:', sortingDimension, sortingDimensionOptions)
  const [filter1Open, setFilter1Open] = useState(false)
  const [filter2Open, setFilter2Open] = useState(false)
  const [filter3Open, setFilter3Open] = useState(false)
  const [filter4Open, setFilter4Open] = useState(false)

  return (
    <div className="flex flex-col items-center justify-center p-2 rounded-lg relative">
      {/* <div className="absolute top-0 left-0 bg-white blur-md" /> */}
      <Popover modal open={filter1Open} onOpenChange={setFilter1Open}>
        <PopoverTrigger className="relative">
          <div className="absolute top-0 left-0 blur-sm w-full h-full bg-white -z-10" />
          <div className="text-[14px] italic flex items-center gap-1 px-3">
            {userDimensionOptions.find((x) => x.value === userDimension)
              ?.label || 'Select'}

            <img src={DropDownSVG} className="w-[18px] h-[18px] shrink-0" />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-fit py-2 px-1 flex flex-col gap-1">
          {userDimensionOptions.map((x) => {
            return (
              <div
                key={x.value}
                className={cn(
                  'hover:bg-[#d5e7c4] px-2 transition-all rounded-md cursor-pointer',
                  {
                    'font-semibold': userDimension === x.value,
                  }
                )}
                onClick={() => {
                  setUserDimension(x.value)
                  setFilter1Open(false)
                  // setTimeFilterOpen(false)
                }}
              >
                {x.label}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>

      <Popover modal open={filter2Open} onOpenChange={setFilter2Open}>
        <PopoverTrigger className="relative  flex">
          <div className="absolute top-0 left-0 blur-sm w-full h-full bg-white -z-10" />
          <div className="text-[14px] italic flex items-center gap-1 px-3 whitespace-nowrap">
            {periodDimensionOptions.find((x) => x.value === periodDimension)
              ?.label || 'Select'}
            {/* <ChevronDown size={18} className="" /> */}
            <img
              src={DropDownSVG}
              className="w-[18px] h-[18px] flex-shrink-0"
            />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-fit py-2 px-1 flex flex-col gap-1">
          {periodDimensionOptions.map((x) => {
            return (
              <div
                key={x.value}
                className={cn(
                  'hover:bg-[#d5e7c4] px-2 transition-all rounded-md cursor-pointer',
                  {
                    'font-semibold': periodDimension === x.value,
                  }
                )}
                onClick={() => {
                  setPeriodDimension(x.value)
                  setFilter2Open(false)
                  // setOrderFilterOpen(false)
                }}
              >
                {x.label}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>

      <Popover modal open={filter3Open} onOpenChange={setFilter3Open}>
        <PopoverTrigger className="relative">
          <div className="absolute top-0 left-0 blur-sm w-full h-full bg-white -z-10" />
          <div className="text-[14px] italic flex items-center gap-1 px-3">
            {impressionsDimensionOptions.find(
              (x) => x.value === impressionsDimension
            )?.label || 'Select'}

            <img src={DropDownSVG} className="w-[18px] h-[18px]" />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-fit py-2 px-1 flex flex-col gap-1">
          {impressionsDimensionOptions.map((x) => {
            return (
              <div
                key={x.value}
                className={cn(
                  'hover:bg-[#d5e7c4] px-2 transition-all rounded-md cursor-pointer',
                  {
                    'font-semibold': impressionsDimension === x.value,
                  }
                )}
                onClick={() => {
                  setImpressionsDimension(x.value)
                  setFilter3Open(false)
                  // setTimeFilterOpen(false)
                }}
              >
                {x.label}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>

      <Popover modal open={filter4Open} onOpenChange={setFilter4Open}>
        <PopoverTrigger className="relative">
          <div className="absolute top-0 left-0 blur-sm w-full h-full bg-white -z-10" />
          <div className="text-[14px] italic flex items-center gap-1 px-3">
            {sortingDimensionOptions.find((x) => x.value === sortingDimension)
              ?.label || 'Select'}

            <img src={DropDownSVG} className="w-[18px] h-[18px]" />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-fit py-2 px-1 flex flex-col gap-1">
          {sortingDimensionOptions.map((x) => {
            return (
              <div
                key={x.value}
                className={cn(
                  'hover:bg-[#d5e7c4] px-2 transition-all rounded-md cursor-pointer',
                  {
                    'font-semibold': sortingDimension === x.value,
                  }
                )}
                onClick={() => {
                  setSortingDimension(x.value)
                  setFilter4Open(false)
                  // setTimeFilterOpen(false)
                }}
              >
                {x.label}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>
    </div>
  )
}
