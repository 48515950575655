import { XIcon } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'
import FilterWheelEntry from '../../components-v2/FilterWheel3/FilterWheelEntry'
import Tabs from '../../components-v2/Tabs'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  deleteArticlesForTopic,
  getTopicArticles,
} from '../../redux/article/actions'
import {
  alarmToggleTopic,
  deletePost,
  getAllPosts,
  getAllPostsForTopics,
  voteToggleTopic,
} from '../../redux/posts/actions'
import {
  deleteTopic,
  getTopic,
  setClickedTopic,
} from '../../redux/topics/actions'
import DescriptionCard from '../ArticleScreen/DescriptionCard'
import {
  filterPosts,
  getDisabledStates,
  getOrderFilteredPosts,
  getTimeFilteredPosts,
} from './helpers'
import './index.css'
import PostsTab from './tabs/PostsTab'
import ArticlesTab from './tabs/ArticlesTab'
import { isAuthenticated } from '../../utils/auth'
import { getMe } from '../../redux/login/actions'
import {
  getATopic,
  getArticlesOfATopic,
  getPostsOfATopicPublic,
} from '../../services/public'
import NoAuthHeader from '../../components-v2/Header/NoAuthHeader'
import NoAuthDialog from '../../components-v2/NoAuthDialog'

const TopicScreenPublic = ({
  getTopic,
  setClickedTopic,

  setPostModalVisible,
  setSelectTopicPostCreateModalVisible,
  setContrastsModalVisible,
  topicPosts,
  getAllPostsForTopics,
  getTopicArticles,
  deletePost,
  deleteTopic,

  topicArticles = [],
  voteToggleTopic,
  deleteArticlesForTopic,
  setParamsForArticleModal,
  pageArticle,
  setPageArticle,
  addPostsToRedux,
  alarmToggleTopic,
  permissions,
  getMe,
  constTopicId,
  constPopup,
  constDefaultOpen,
}: any) => {
  const [isNoAuthDialogOpen, setIsNoAuthDialogOpen] = useState(false)
  // console.log('🚀 ~ topic:', topic)
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState('posts')
  const params = useParams()
  const { topicId: idFromParam } = params
  // let idFromParam = ''

  let topicId = constTopicId || idFromParam

  // useEffect(() => {
  //   if (params?.topicId) {
  //     idFromParam = params?.topicId ?? ''
  //   } else {
  //     idFromParam = params?.localId ?? ''
  //   }
  //   topicId = idFromParam
  // }, [params])

  const [searchParams, setSearchParams] = useSearchParams()

  const [loadingPosts, setLoadingPosts] = useState(false)
  const [posts, setPosts] = useState<any[]>([])

  const [loadingArticles, setLoadingArticles] = useState(false)
  const [articles, setArticles] = useState<any[]>([])

  const [filterPostType, setFilterPostType] = useState('all')
  const [filterOrganization, setFilterOrganization] = useState('all')

  const defaultFilterTime = 'all'
  const [filterTime, setFilterTime] = useState(defaultFilterTime)
  const defaultFilterOrder = 'new-to-old'
  const [filterOrder, setFilterOrder] = useState(defaultFilterOrder)

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)

  const [topic, setTopic] = useState<any>(null)

  const isAuthed = isAuthenticated()

  useEffect(() => {
    if (isAuthed) {
      navigate('/')
    }
  }, [isAuthed])

  const loadPosts = useCallback(() => {
    if (topicId) {
      setLoadingPosts(true)
      if (topicId === 'local') {
        //if the topic is local
      } else {
        //if it is not a local topic
        getPostsOfATopicPublic(topicId)
          .then((data) => {
            setPosts(data)
          })
          .finally(() => {
            setLoadingPosts(false)
          })
      }
    }
  }, [topicId])

  const loadArticles = useCallback(() => {
    if (topicId) {
      setLoadingArticles(true)
      getArticlesOfATopic(topicId)
        .then((data) => {
          setArticles(data)
        })
        .finally(() => {
          setLoadingArticles(false)
        })
    }
  }, [topicId])

  useEffect(() => {
    if (topicId) {
      getATopic(topicId).then((data) => {
        setTopic(data)
      })
    }
  }, [topicId])

  useEffect(() => {
    if (currentTab === 'posts') {
      loadPosts()
    }

    if (currentTab === 'articles') {
      loadArticles()
    }
  }, [currentTab, loadPosts, loadArticles])

  // TODO: memoize this for performance
  const disabledItems = getDisabledStates(posts, topic?.associatedUsers)

  let tab = null
  switch (currentTab) {
    case 'posts':
      const filteredLayer1 = filterPosts(
        posts,
        filterPostType,
        filterOrganization,
        topic?.associatedUsers
      )
      const filteredLayer2 = getTimeFilteredPosts(filteredLayer1, filterTime)
      const filteredLayer3 = getOrderFilteredPosts(filteredLayer2, filterOrder)

      tab = (
        <PostsTab
          posts={filteredLayer3}
          cb={loadPosts}
          isLoading={loadingPosts}
          onNoAuthCallback={onNoAuthDialogOpen}
        />
      )
      break

    case 'articles':
      tab = (
        <ArticlesTab
          articles={articles}
          cb={loadArticles}
          isLoading={loadingArticles}
          onNoAuthCallback={onNoAuthDialogOpen}
        />
      )
      break
  }

  function onNoAuthDialogOpen() {
    setIsNoAuthDialogOpen(true)
  }

  const defaultPopupOpen =
    constDefaultOpen || Number(searchParams.get('defaultOpen')) === 1

  const popup = constPopup || searchParams.get('popup')

  let popupUrl = ''
  switch (popup) {
    case 'niooze':
      popupUrl = 'https://niooze.carrd.co/'
      break

    default:
      popupUrl = 'https://niooze-signup.carrd.co/'
      break
  }

  return (
    <>
      <NoAuthHeader noAuthClick={onNoAuthDialogOpen} />
      <div className={'mb-8'}>
        <div className="h-[38px]">
          <div className="h-[38px] justify-between fixed w-full z-30 bg-white border-b">
            <div className="flex items-center max-w-[468px] mx-auto ">
              <img
                src={ArrowLeftSVG}
                className="w-6 h-6 cursor-pointer hover:bg-line transition-all rounded-lg"
                onClick={() => {
                  // navigate(-1)
                  onNoAuthDialogOpen()
                }}
              />
              <Tabs
                className="py-2 items-center "
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                tabs={[
                  {
                    value: 'posts',
                    label: 'Posts',
                  },
                  {
                    value: 'articles',
                    label: 'Articles',
                  },
                ]}
              />

              <div className="w-6 h-6" />
            </div>
          </div>
        </div>

        {topic && (
          <div className="hidden max-w-[468px] mx-desktop:block mx-auto">
            <DescriptionCard
              permissions={permissions}
              title={topic.title}
              description={topic?.description}
              location={topic?.locationId}
              topicId={topic.id}
              cb={() => {
                loadPosts()
                // loadArticles()
              }}
              onNoAuthCallback={onNoAuthDialogOpen}
            />
          </div>
        )}
        <div className="flex mx-desktop:flex-col w-full max-w-[2000px] mx-auto">
          {/* Left div */}
          <div
            className="min-h-44 mx-desktop:hidden relative"
            style={{
              flex: '0 0 30%',
            }}
          >
            {topic && (
              <div className="fixed w-[30%] max-w-[600px]">
                <DescriptionCard
                  permissions={permissions}
                  title={topic.title}
                  description={topic?.description}
                  location={topic?.locationId}
                  topicId={topic.id}
                  cb={() => {
                    loadPosts()
                    // loadArticles()
                  }}
                  onNoAuthCallback={onNoAuthDialogOpen}
                />
                {/* <FilterEntry
                currentPostType={filterPostType}
                setPostType={setFilterPostType}
              /> */}

                {/* <PostTypeWheel /> */}
                {currentTab === 'posts' && (
                  <FilterWheelEntry
                    className="mt-10"
                    currentPostType={filterPostType}
                    setPostType={(type: string) => {
                      setFilterOrganization('all')
                      setFilterPostType(type)
                    }}
                    currentOrgType={filterOrganization}
                    setOrgType={(org: string) => {
                      setFilterPostType('all')
                      setFilterOrganization(org)
                    }}
                    setFilterOrder={setFilterOrder}
                    filterOrder={filterOrder}
                    setFilterTime={setFilterTime}
                    filterTime={filterTime}
                    postTypeWheelOpen={postTypeWheelOpen}
                    setPostTypeWheelOpen={setPostTypeWheelOpen}
                    orgWheelOpen={orgWheelOpen}
                    setOrgWheelOpen={setOrgWheelOpen}
                    disabledPostTypes={disabledItems.postTypes}
                    disabledOrgTypes={disabledItems.orgTypes}
                  />
                )}
              </div>
            )}
          </div>

          {/* Middle div */}
          <div
            className=" flex flex-col items-center border-r mx-desktop:border-r-0 border-line mx-mobile:px-5 min-h-screen"
            style={{
              flex: '0 0 40%',
            }}
          >
            {/* Filters */}
            {currentTab === 'posts' && (
              <div className="flex items-center gap-1 mt-5 mb-4 flex-wrap mobile:px-5 ">
                {filterPostType !== 'all' && (
                  <div
                    className="text-sm bg-[#d5e7c4] px-2 py-1 rounded-md text-white flex items-center cursor-pointer"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    Filtering {filterPostType}
                    <XIcon
                      className="flex-1 mb-[1px] -mr-[4px] cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterPostType('all')
                      }}
                    />
                  </div>
                )}

                {filterOrganization !== 'all' && (
                  <div
                    className="text-sm bg-[#d5e7c4] px-2 py-1 rounded-md text-white flex items-center cursor-pointer"
                    onClick={() => {
                      setOrgWheelOpen(true)
                    }}
                  >
                    Filtering {filterOrganization}
                    <XIcon
                      className="flex-1 mb-[1px] -mr-[4px] cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrganization('all')
                      }}
                    />
                  </div>
                )}

                {/* {filterTime && filterTime !== 'all' && (
                  <div
                    className="text-sm bg-[#d5e7c4] px-2 py-1 rounded-md text-white flex items-center gap-1 cursor-pointer"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      timeFilterOptions.find((x) => x.value === filterTime)
                        ?.label
                    }
                    <XIcon
                      className="flex-1 mb-[1px] -mr-[4px] cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterTime(defaultFilterTime)
                      }}
                    />
                  </div>
                )} */}

                {/* {filterOrder && filterOrder !== 'new-to-old' && (
                  <div
                    className="text-sm bg-[#d5e7c4] px-2 py-1 rounded-md text-white flex items-center gap-1 cursor-pointer"
                    onClick={() => {
                      setPostTypeWheelOpen(true)
                    }}
                  >
                    {
                      orderFilterOptions.find((x) => x.value === filterOrder)
                        ?.label
                    }

                    <XIcon
                      className="flex-1 mb-[1px] -mr-[4px] cursor-pointer hover:text-gray-500"
                      size={18}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilterOrder(defaultFilterOrder)
                      }}
                    />
                  </div>
                )} */}
              </div>
            )}

            {tab}
          </div>
          {/* Right div */}
          <div
            className="min-h-44 relative mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          ></div>
        </div>
        {currentTab === 'posts' && (
          <div
            className="fixed bottom-3 right-3 bg-white z-50 rounded-full desktop:hidden h-"
            style={{
              // '-webkit-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              // '-moz-box-shadow': '0px 0px 49px 21px rgba(166,166,166,1)',
              boxShadow: 'rgb(255, 255, 255) 0px 0px 15px 8px',
            }}
          >
            <FilterWheelEntry
              currentPostType={filterPostType}
              setPostType={(type: string) => {
                setFilterOrganization('all')
                setFilterPostType(type)
              }}
              currentOrgType={filterOrganization}
              setOrgType={(org: string) => {
                setFilterPostType('all')
                setFilterOrganization(org)
              }}
              setFilterOrder={setFilterOrder}
              filterOrder={filterOrder}
              setFilterTime={setFilterTime}
              filterTime={filterTime}
              postTypeWheelOpen={postTypeWheelOpen}
              setPostTypeWheelOpen={setPostTypeWheelOpen}
              orgWheelOpen={orgWheelOpen}
              setOrgWheelOpen={setOrgWheelOpen}
              disabledPostTypes={disabledItems.postTypes}
              disabledOrgTypes={disabledItems.orgTypes}
            />
          </div>
        )}
      </div>
      <NoAuthDialog
        setOpen={setIsNoAuthDialogOpen}
        open={isNoAuthDialogOpen}
        url={popupUrl}
        defaultOpen={defaultPopupOpen}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,
    permissions: state?.login?.user?.roles,

    topic: state.topics.topic,

    topicArticles: state.articles.topicArticles,
    topicsID: state.topics.topicsID,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },
    getTopic: (id: string) => {
      dispatch(getTopic(id))
    },
    getMe: () => {
      dispatch(getMe())
    },
    getTopicArticles: (topicId: string) => {
      dispatch(getTopicArticles(topicId))
    },
    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    deleteTopic: (topic: any) => {
      dispatch(deleteTopic(topic))
    },
    voteToggleTopic: (postId: any, topicId: any) => {
      dispatch(voteToggleTopic(postId, topicId))
    },
    alarmToggleTopic: (postId: any, topicId: any) => {
      dispatch(alarmToggleTopic(postId, topicId))
    },
    deleteArticlesForTopic: (artcileId: any, topicId: any) => {
      dispatch(deleteArticlesForTopic(artcileId, topicId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicScreenPublic)
