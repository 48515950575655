import React, { useEffect } from 'react'
import authAxios from '../../../utils/authAxios'
import { useQuery } from '@tanstack/react-query'
import Loading from '../../../components-v2/Loading'
import { Link } from 'react-router-dom'

type Props = {}

export default function TrendingTopics({}: Props) {
  const { isFetching, error, data } = useQuery({
    queryKey: ['getTrendingNioozeTopics'],
    queryFn: async () => {
      const res = await authAxios.post('/v2/topic/getTrendingNioozeTopics')
      return res.data as any[]
    },
    initialData: [],
    refetchOnWindowFocus: false,
  })

  return (
    <div className="text-primary">
      <h2 className="text-[14px] mt-5 text-center font-light">
        Select one of the below topics to keep reading
      </h2>
      {isFetching && (
        <div className="flex justify-center mt-2">
          <Loading />
        </div>
      )}

      <div className="mt-10 border-l border-l-border flex flex-col gap-1">
        {data.map((x) => {
          return (
            <Link
              to={`/topic/${x.id}`}
              key={x.id}
              className="text-[14px] font-light hover:underline pl-[16px] cursor-pointer"
            >
              {x.title}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
