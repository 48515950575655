import React from 'react'

type Props = {
  content: string
  role?: string
  source?: string
  date?: Date
}

export default function QuoteContent({ content, date, role, source }: Props) {
  return (
    <div className="text-[14px] mt-[19px] space-y-[13px] leading-[21px]">
      <p className="">
        <span className="">“</span>
        <span className="">{content}</span>
        <span className="">”</span>
      </p>

      <div className="text-[12px] text-border leading-[16px]">
        {role && <p>{role}</p>}

        {source && (
          <p>
            <span className="">Source</span>: {source}
          </p>
        )}

        {date && (
          <p>
            <span className="">Date</span>: {date?.toDateString()}
          </p>
        )}
      </div>
    </div>
  )
}
