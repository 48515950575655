import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './index.css'

import checkLogo from '../../assets/icons/check-logo.svg'

import { addUserCredentials } from '../../redux/login/actions'

import InputText from '../../components/InputText'
import OnBoardButton from '../../components/OnBoardButton'
import Spacer from '../../components/Spacer'
import { toast } from 'sonner'
import { z } from 'zod'
import { notEmpty } from '../../utils/formHelpers'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import Button from '../../components-v2/Button'
import Loading from '../../components-v2/Loading'
import { Input } from '../../components-v2/shadcn/input'

const schema = z.object({
  email: z.string().email().pipe(notEmpty),
  password: z.string().pipe(notEmpty),
})

type SchemaType = z.infer<typeof schema>

const SignUpEmail = ({
  usersTempId,
  userTempIdLoading,
  addUserCredentials,
  userDetails,
}: any) => {
  const navigate = useNavigate()

  const [formError, setFormError] = useState('')

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {},
  })

  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [buttonDisable, setButtonDisable] = useState(true)

  // const [emailError, setEmailError] = useState('')

  // function handleSubmit() {
  //   if (
  //     /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
  //     password
  //   ) {
  //     navigate('/profilesetup')
  //     addUserCredentials(email, password)
  //   } else {
  //     toast.error('Email and password is required!')
  //   }
  // }

  // useEffect(() => {
  //   if (
  //     /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
  //     password
  //   ) {
  //     setButtonDisable(false)
  //   }
  // }, [email, password])

  // const onSetEmail = (text: any) => {
  //   setEmail(text)
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
  //     setEmailError('')
  //   } else {
  //     setEmailError('Email is not valid')
  //   }
  // }

  return (
    <div className="main-div-sign-up min-h-screen overflow-y-hidden">
      {/*main div*/}
      <div className={'flex justify-center'}>
        {/*check logo div*/}
        <img className={'image-size-sign-up'} src={checkLogo} alt="" />
        {/*check logo*/}
      </div>

      <div className={'check-text-margin-top-sign-up text-center'}>
        <text className={'check-text-sign-up mt-10'}>niooze</text>
      </div>

      <form
        className="max-w-[300px] mx-auto mt-[94px] text-white mb-20"
        onSubmit={handleSubmit(async (values) => {
          addUserCredentials(values.email, values.password)
          navigate('/profilesetup')
        })}
      >
        <div className=" font-bold">
          <p>
            Complete your <br /> login details
          </p>
        </div>

        <div className="pt-[40px]">
          <p className="text-[14px] font-bold mb-[6px]">Email address*</p>
          <Input
            className="h-[29px] bg-transparent border-line border-2 placeholder:text-line placeholder:text-[11px] placeholder:italic text-white"
            placeholder="Email"
            {...register('email')}
          />
          {errors?.email && (
            <p className="text-[14px] text-red-500 font-bold mt-1">
              {errors?.email?.message}
            </p>
          )}
        </div>

        <div className="pt-[40px]">
          <p className="text-[14px] font-bold mb-[6px]">Password*</p>
          <Input
            type="password"
            className="h-[29px] bg-transparent border-line border-2 placeholder:text-line placeholder:text-[11px] placeholder:italic text-white"
            placeholder="Password"
            {...register('password')}
          />
          {errors?.password && (
            <p className="text-[14px] text-red-500 font-bold mt-1">
              {errors?.password?.message}
            </p>
          )}
        </div>

        <div className=" mt-[90px] flex flex-col items-center">
          {formError && (
            <p className="text-[14px] text-red-500 font-bold mb-1">
              {formError}
            </p>
          )}
          <Button
            disabled={isSubmitting}
            type="submit"
            className="text-black w-full flex justify-center items-center gap-2 transition-all"
          >
            Continue {isSubmitting && <Loading />}
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    usersTempId: state.login.userTempId,
    userTempIdLoading: state.login.userTempIdLoading,
    userDetails: state.login.userDetails,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUserCredentials: (email: any, password: any) => {
      dispatch(addUserCredentials(email, password))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpEmail)
