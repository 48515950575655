import React, { useCallback, useState } from 'react'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../shadcn/accordion'
import { Link, useNavigate } from 'react-router-dom'
import { Heart } from 'lucide-react'
import { Input } from '../../shadcn/input'
import whiteLens from '../../../assets/icons/lens-white.svg'
import PartyCard from '../../EntryDialog/dialogs/AddTopic/PartyCard'
import debounce from '../../../utils/debounce'
import { searchUsers } from '../../../services/user'
import Loading from '../../Loading'

type Props = {
  topics: any[]
  setIsOpen?: (isOpen: boolean) => void
}

export default function FollowingTopicsAccordion({ topics, setIsOpen }: Props) {
  return (
    <>
      <AccordionItem value={'following'}>
        <AccordionTrigger
          className="p-0
        pr-3
         pb-1 pl-3 hover:underline"
        >
          <div className="flex w-full cursor-pointer items-center justify-between [&_#toggle]:data-[state=open]:rotate-180">
            <div className="flex items-center gap-[13px]">
              <Heart color="#303655" />
              <p className=" text-[16px] font-normal text-primary">Following</p>
            </div>

            {/* <ChevronDown className="transition-all" id="toggle" /> */}
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex translate-x-[15px] flex-col gap-1 border-l border-primary pb-[12px] pl-[17px] ml-[8px] text-primary">
          {topics.length <= 0 && (
            <p className="font-light text-border pl-2">
              Not following any topics
            </p>
          )}

          <div className="h-[6px] w-1"></div>
          {topics?.map((topic: any) => {
            //   if (topic?.posts?.length <= 0) {
            //     return null
            //   }
            return (
              <Link
                to={`/topic/${topic.id}`}
                key={topic.id}
                className="cursor-pointer text-[14px] font-light hover:underline pl-[8px]"
                onClick={() => {
                  setIsOpen && setIsOpen(false)
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }}
              >
                {topic.title}
              </Link>
            )
          })}
        </AccordionContent>
      </AccordionItem>
    </>
  )
}
