import React, { useCallback, useEffect, useState } from 'react'
import { followATopic, getTopicStatus, unfollowATopic } from '../services/topic'
import { cn } from '../utils/cn'
import Loading from './Loading'
import { useSidebarStore } from './Header/Sidebar/topic.store'

type Props = {
  topicId: string
  cb?: () => void
  className?: string
}

export default function TopicFollowButton({ cb, topicId, className }: Props) {
  const [isReady, setIsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFollowed, setIsFollowed] = useState(false)

  const fetchFollowingTopics = useSidebarStore(
    (state: any) => state.fetchFollowingTopics
  )

  const loadTopicStatus = useCallback(() => {
    if (topicId) {
      getTopicStatus(topicId).then((data) => {
        setIsFollowed(data.followed)
        setIsReady(data.followed !== null)
      })
    }
  }, [topicId])

  useEffect(() => {
    loadTopicStatus()
  }, [loadTopicStatus])

  return (
    <div
      className={cn('flex cursor-pointer items-center', {
        hidden: !isReady,
      })}
    >
      {isFollowed ? (
        <span
          className={cn(
            '-mr-[3px] whitespace-nowrap rounded px-[3px] text-[14px] font-semibold text-border transition-all hover:bg-line',
            {
              'pointer-events-none opacity-50': isLoading,
            },
            className
          )}
          onClick={(e) => {
            e.stopPropagation()
            setIsLoading(true)
            unfollowATopic(topicId)
              .then(() => {
                cb && cb()
                fetchFollowingTopics()
                loadTopicStatus()
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        >
          ✓ Following
        </span>
      ) : (
        <span
          className={cn(
            '-mr-[3px] whitespace-nowrap rounded px-[3px] text-[14px] font-semibold text-[#2443B2] transition-all hover:bg-border',
            {
              'pointer-events-none opacity-50': isLoading,
            },
            className
          )}
          onClick={(e) => {
            e.stopPropagation()
            setIsLoading(true)
            followATopic(topicId)
              .then(() => {
                cb && cb()
                fetchFollowingTopics()
                loadTopicStatus()
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        >
          + Follow
        </span>
      )}
    </div>
  )
}
