import React, { useCallback, useEffect, useState } from 'react'
import { cn } from '../../../utils/cn'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '../../shadcn/collapsible'
import {
  ChevronDown,
  Heart,
  Landmark,
  PersonStanding,
  Earth,
  DollarSign,
  Cpu,
  CirclePlay,
  Sprout,
  FlaskConical,
  HeartPulse,
  GraduationCap,
  Palette,
  Shirt,
  Medal,
  Circle,
} from 'lucide-react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../shadcn/accordion'
import {
  getFollowingTopics,
  getTopicCategoriesWithTopics,
} from '../../../services/topic'
import { getIcon } from './helper'
import { Link, useNavigate } from 'react-router-dom'
import FollowingTopicsAccordion from './FollowingTopicsAccordion'
import { useSidebarStore } from './topic.store'
import UserSearch from '../../UserSearch/UserSearch'

type Props = {
  setIsOpen?: (isOpen: boolean) => void
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>

export default function SidebarContent({ setIsOpen }: Props) {
  // const categories = [
  //   {
  //     name: 'Society',
  //     id: '1',
  //     topics: [
  //       'Social inequality',
  //       'Cultural diversity',
  //       'Community development',
  //     ],
  //   },
  //   {
  //     name: 'Politics',
  //     id: '2',
  //     topics: ['Global diplomacy', 'Election campaigns', 'Government policies'],
  //   },
  //   {
  //     name: 'World',
  //     id: '3',
  //     topics: [
  //       'International conflicts',
  //       'Humanitarian crises',
  //       'Globalization trends',
  //     ],
  //   },
  //   {
  //     name: 'Business',
  //     id: '4',
  //     topics: ['Market trends', 'Corporate mergers', 'Entrepreneurship'],
  //   },
  //   {
  //     name: 'Technology',
  //     id: '5',
  //     topics: [
  //       'Artificial intelligence',
  //       'Blockchain technology',
  //       'Cybersecurity',
  //     ],
  //   },
  //   {
  //     name: 'Entertainment',
  //     id: '6',
  //     topics: ['Film industry', 'Music releases', 'Celebrity gossip'],
  //   },
  //   {
  //     name: 'Environment',
  //     id: '7',
  //     topics: ['Climate change', 'Renewable energy', 'Wildlife conservation'],
  //   },
  //   {
  //     name: 'Science',
  //     id: '8',
  //     topics: [
  //       'Space exploration',
  //       'Genetic engineering',
  //       'Medical advancements',
  //     ],
  //   },
  //   {
  //     name: 'Health',
  //     id: '9',
  //     topics: [
  //       'Public health crises',
  //       'Medical breakthroughs',
  //       'Mental wellness',
  //     ],
  //   },
  //   {
  //     name: 'Education',
  //     id: '10',
  //     topics: [
  //       'Education reform',
  //       'Online learning platforms',
  //       'STEM education',
  //     ],
  //   },
  //   {
  //     name: 'Art & culture',
  //     id: '11',
  //     topics: [
  //       'Art exhibitions',
  //       'Literary festivals',
  //       'Cultural heritage preservation',
  //     ],
  //   },
  //   {
  //     name: 'Lifestyle',
  //     id: '12',
  //     topics: ['Travel destinations', 'Wellness trends', 'Food culture'],
  //   },
  //   {
  //     name: 'Sports',
  //     id: '13',
  //     topics: [
  //       'Major sporting events',
  //       'Athlete achievements',
  //       'Team rivalries',
  //     ],
  //   },
  // ]

  const categories = useSidebarStore<any[]>((state: any) => state.categories)
  // console.log('🚀 ~ SidebarContent ~ categories:', categories)
  const fetchCategories = useSidebarStore((state: any) => state.fetchCategories)

  const followingTopics = useSidebarStore((state: any) => state.followingTopics)
  const fetchFollowingTopics = useSidebarStore(
    (state: any) => state.fetchFollowingTopics
  )

  const currentAccordion = useSidebarStore(
    (state: any) => state.currentAccordion
  )
  const setCurrentAccordion = useSidebarStore(
    (state: any) => state.setCurrentAccordion
  )

  // const [categories, setCategories] = useState<any[]>([])
  // const [followingTopics, setFollowingTopics] = useState<any[]>([])

  useEffect(() => {
    // getTopicCategoriesWithTopics().then((data) => {
    //   // console.log('🚀 ~ getTopicCategoriesWithTopics ~ data:', data)
    //   setCategories(data)
    // })

    fetchCategories()
    fetchFollowingTopics()
  }, [])

  // useEffect(() => {
  //   getFollowingTopics().then((data) => {
  //     // console.log('🚀 ~ getFollowingTopics ~ data:', data)

  //     setFollowingTopics(data)
  //   })

  // }, [])

  return (
    <div className="">
      <div className={cn('w-[306px] p-[23px] fixed bg-white')}>
        {/* <div className="space-y-[15px]"> */}
        {/* {categories.map((x) => {
          return (
            <Collapsible key={x.id}>
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-between w-full cursor-pointer [&_#toggle]:data-[state=open]:rotate-180 ">
                  <div className="flex items-center gap-[15px]">
                    <Heart />
                    <p className="text-sm">{x.name}</p>
                  </div>

                  <ChevronDown className="transition-all" id="toggle" />
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="border-l border-border pl-[14px] translate-x-[12px]">
                {x.topics.map((y) => {
                  return <p>{y}</p>
                })}
              </CollapsibleContent>
            </Collapsible>
          )
        })} */}
        <div className={'mb-4 content-center'}>
          <UserSearch setIsOpen={setIsOpen} />
        </div>

        <Accordion
          type="single"
          collapsible
          className="max-h-[80dvh] space-y-[13px] overflow-y-auto overflow-x-hidden scrollbar-hide "
          // value="following"
          value={currentAccordion}
          onValueChange={setCurrentAccordion}
        >
          <FollowingTopicsAccordion
            topics={followingTopics}
            setIsOpen={setIsOpen}
          />

          {categories.map((category, i) => {
            if (category.topics <= 0) {
              return null
            }

            if (!category.topics?.find((t: any) => t.posts?.length > 0)) {
              return null
            }

            const CatIcon = getIcon(category.icon)
            return (
              <AccordionItem value={category.id} key={i}>
                <AccordionTrigger className="p-0 pb-1 pr-3 pl-[10px] hover:underline">
                  <div className="flex w-full cursor-pointer items-center justify-between [&_#toggle]:data-[state=open]:rotate-180">
                    <div className="flex items-center gap-[15px]">
                      {CatIcon ? <CatIcon color="#303655" /> : <Circle />}
                      {/* <Heart /> */}
                      <p className="text-[16px] font-normal text-primary">
                        {category.name}
                      </p>
                    </div>

                    {/* <ChevronDown className="transition-all" id="toggle" /> */}
                  </div>
                </AccordionTrigger>
                <AccordionContent className="flex translate-x-[12px] flex-col gap-1 border-l border-primary pb-[12px] pl-[11px] text-primary ml-[9px]">
                  <div className="h-[6px] w-1"></div>
                  {category?.topics?.map((topic: any) => {
                    if (topic?.posts?.length <= 0) {
                      return null
                    }
                    return (
                      <Link
                        to={`/topic/${topic.id}`}
                        key={topic.id}
                        className="cursor-pointer text-[14px] font-light hover:underline pl-[16px]"
                        onClick={() => {
                          setIsOpen && setIsOpen(false)
                        }}
                      >
                        {topic.title}
                      </Link>
                    )
                  })}
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}
