import authAxios from "../../utils/authAxios";

export async function getTrustFeedChangesPosts({queryKey,pageParam}: any) {

    const res = await authAxios.post('/v2/trustfeed/changes', {
        pagination: {
            page: pageParam,
            limit: 8,
        },
    })

    return res.data as any
}

export async function getTrustFeedContrastsPosts({queryKey,pageParam}: any) {

    const res = await authAxios.post('/v2/trustfeed/contrasts', {
        pagination: {
            page: pageParam,
            limit: 8,
        },
    })

    return res.data as any
}