import React, { useEffect } from 'react'
import Button from '../../components-v2/Button'
import { useForm, Field, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Switch } from '../../components-v2/shadcn/switch'
import { toast } from 'sonner'
import { useParams } from 'react-router-dom'
import { getEmailSettingsByToken, saveEmailSettings } from './helper'
import { useQuery } from '@tanstack/react-query'
import NoAuthHeader from '../../components-v2/Header/NoAuthHeader'
import PublicHeader from '../../components-v2/Header/PublicHeader'
import {
  RadioGroup,
  RadioGroupItem,
} from '../../components-v2/shadcn/radio-group'

type Props = {
  loadFn: () => any
  saveFn: (token: string, data: any) => any
  emailType: 'user-email' | 'waitlist-email'
}

const schema = z.object({
  // daily: z.boolean(),
  // twoDaily: z.boolean(),
  // weekly: z.boolean(),

  frequency: z.string(),

  engagements: z.boolean(),
  trustfeed: z.boolean(),
  topPicks: z.boolean(),
})

type SchemaType = z.infer<typeof schema>

export default function EmailPreference({ loadFn, saveFn, emailType }: Props) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      // daily: true,
      engagements: true,
      topPicks: true,
      trustfeed: true,
      frequency: 'none',
      // twoDaily: true,
      // weekly: true,
    },
  })

  const frequencyCheckboxes = [
    ...(emailType === 'user-email'
      ? [
          {
            name: 'daily-if-no-visit',
            label: 'Daily, but only if I did not visit niooze.com that day',
          },
        ]
      : []),

    {
      name: 'daily',
      label: 'Daily',
    },
    {
      name: 'twoDaily',
      label: '2-Daily',
    },
    {
      name: 'weekly',
      label: 'Weekly',
    },
    {
      name: 'never',
      label: 'Never',
    },
  ]

  const sectionCheckboxes = [
    ...(emailType === 'user-email'
      ? [
          {
            name: 'engagements',
            label: 'My posts engagement',
          },
        ]
      : []),

    ...(emailType === 'user-email'
      ? [
          {
            name: 'trustfeed',
            label: 'Trust feed',
          },
        ]
      : []),
    {
      name: 'topPicks',
      label: 'Top picks & topics',
    },
  ]

  const { token } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ['emailSettings', token],
    // queryFn: getEmailSettingsByToken,
    queryFn: loadFn,
    enabled: !!token,
  })

  // const { data, isLoading, error } = useQuery(
  //   ['emailSettings', token],
  //   () => () => {
  //     if (token) {
  //       getEmailSettingsByToken(token)
  //     }
  //   }
  // )

  useEffect(() => {
    if (data) {
      // setValue('daily', data.daily)
      // setValue('twoDaily', data.twoDaily)
      // setValue('weekly', data.weekly)
      setValue('frequency', data.frequency)
      setValue('engagements', data.engagements)
      setValue('topPicks', data.topPicks)
      setValue('trustfeed', data.trustfeed)
    }
  }, [data])

  return (
    <>
      <PublicHeader />

      <main className="max-w-[436px] w-full mx-auto">
        {/* <h1 className="text-base font-semibold mt-5">Create a Houseguest</h1> */}
        <h1 className="text-2xl font-bold mt-5">Email Settings</h1>
        <div className="h-[1px] w-[60px] bg-black mt-[8px]" />

        <form className="flex flex-col gap-5 mt-5 text-[14px]">
          <Controller
            name={'frequency'}
            control={control}
            render={({ field }) => {
              return (
                <div className="p-5 border rounded-lg flex flex-col gap-3">
                  <h2 className="font-bold text-sm">Frequency</h2>
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    {frequencyCheckboxes.map((x) => {
                      return (
                        <div
                          className="flex items-center space-x-2"
                          key={x.name}
                        >
                          <RadioGroupItem value={x.name} id={x.name} />
                          <label htmlFor={x.name} className="cursor-pointer">
                            {x.label}
                          </label>
                        </div>
                      )
                    })}
                  </RadioGroup>
                </div>
              )
            }}
          />

          {/* <div className="p-5 border rounded-lg flex flex-col gap-3">
            <h2 className="font-bold text-sm">Frequency</h2>
            {frequencyCheckboxes.map((x: any) => {
              return (
                <Controller
                  name={x.name}
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className="flex items-center space-x-2">
                        <Switch
                          id={field.name}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                        <label htmlFor={field.name} className="cursor-pointer">
                          {x.label}
                        </label>
                      </div>
                    )
                  }}
                />
              )
            })}
          </div> */}

          <div className="p-5 border rounded-lg flex flex-col gap-3">
            <h2 className="font-bold text-sm">Sections</h2>
            {sectionCheckboxes.map((x: any) => {
              return (
                <Controller
                  name={x.name}
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className="flex items-center space-x-2">
                        <Switch
                          id={field.name}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                        <label
                          htmlFor={field.name}
                          className="cursor-pointer text-[14px]"
                        >
                          {x.label}
                        </label>
                      </div>
                    )
                  }}
                />
              )
            })}
          </div>

          <div className="flex justify-between items-center">
            <p
              className="cursor-pointer"
              onClick={() => {
                // setValue('daily', false)
                // setValue('twoDaily', false)
                // setValue('weekly', false)

                setValue('frequency', 'none')

                setValue('engagements', false)
                setValue('topPicks', false)
                setValue('trustfeed', false)
              }}
            >
              Unsubscribe
            </p>

            <Button
              disabled={isSubmitting}
              onClick={handleSubmit(async (values) => {
                try {
                  // console.log('🚀 ~ onClick={handleSubmit ~ value:', values)
                  // await createHouseguest(value)

                  if (token) {
                    await saveFn(token, values)
                  } else {
                    throw new Error('No Token')
                  }

                  toast.success(`Saved`)
                } catch (error) {
                  console.log(error)
                  toast.error('Something went wrong')
                }
              })}
            >
              Save
            </Button>
          </div>
        </form>
      </main>
    </>
  )
}
