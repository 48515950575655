import { useEffect, useState } from 'react'
import { getAllTopics } from '../../redux/topics/actions'
import { selectPostDetails, postEditRules } from '../../redux/posts/actions'
import { connect, useDispatch } from 'react-redux'
import { getMe } from '../../redux/login/actions'
import { useNavigate } from 'react-router-dom'
import { addPostsToRedux } from '../../redux/analytics/actions'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'
import AddSVG from '../../assets/icons/add.svg'
// import EntryDialog from '../../containers/PostScreen/EntryDialog'
import EntryDialog from '../../components-v2/EntryDialog/EntryDialog'
import { ChevronRight } from 'lucide-react'
import {
  getTrustFeedChanges,
  trustFeedContrasts,
} from '../../redux/trustFeed/action'
import Header from '../../components-v2/Header/Header'
import { isAuthenticated } from '../../utils/auth'
import SidebarContent from '../../components-v2/Header/Sidebar/SidebarContent'
import { followATopic } from '../../services/topic'
import TopicFollowButton from '../../components-v2/TopicFollowButton'
import { locationToDisplay } from '../../components-v2/Post/helpers'
import FilterWheelEntry from './FilterWheelEntry'
import { getNextPageParam, getNiooze, getNioozeWrapper } from './helper'
import { useHome } from './home.store'
import { useInView } from 'react-intersection-observer'
import Loading from '../../components-v2/Loading'
import { useInfiniteQuery } from '@tanstack/react-query'
import { filterConsecutiveCons } from '../../utils/postHelper'
import { getUserFollowerIds } from '../../services/helpersApis'
import isToday from '../../utils/trustFeed'
import TopicComponent from './components/TopicComponent'
import { Topic } from './components/types'
import TrendingTopics from './components/TrendingTopics'
import {isTfAvailable} from "../../services/trustFeed";
const HomeContainer = ({
  addPostsToRedux,
  user,
  getMe,
  getTrustFeedChanges,
  trustFeedContrastsState,
  trustFeedChangesState,
  trustFeedContrasts,
  permissions,
  analyticsPosts,
}: any) => {
  useEffect(() => {
    if (isAuthenticated()) {
      getMe()
      // getUserFollowerIds().then((userIds) => {
      //   setUserFollowIds(userIds)
      // })
    } else {
      // navigate(
      //   '/topic/324d5030-1169-44c4-a88a-97733ac3f6e1?popup=landingPopup&defaultOpen=1'
      // )

      navigate('/landing')
    }
  }, [])

  const [isPageLoading, setIsPageLoading] = useState(true)

  const [topicData, setTopicData] = useState<any[]>([])
  // console.log('🚀 ~ topicData:', topicData)

  const [topicsLoading, setTopicsLoading] = useState(false)

  // is any topic fetching
  useEffect(() => {
    const isAnyFetching = Object.values(topicData).some(
      (item) => item.isFetching
    )
    setTopicsLoading(isAnyFetching)
  }, [topicData])

  const isEmpty = Object.values(topicData).every(
    (topic) => topic.posts.length === 0 && !topic.isFetching
  )

  // useEffect(() => {
  //   // getAllTopics()
  //   if (user?.lastLogin !== null || user?.lastLogin) {
  //     if (!isToday(user?.lastLogin)) {
  //       getTrustFeedChanges()
  //       trustFeedContrasts()
  //     }
  //   }
  // }, [user])
  //
  //   // if (!isToday(user?.lastLogin) || user?.lastLogin === null) {
  //   //   if (
  //   //     trustFeedContrastsState?.length > 0 ||
  //   //     trustFeedChangesState?.length > 0
  //   //   ) {
  //   //     navigate('/trust')
  //   //   }
  //   // }
  // }, [ user?.lastLogin])

  useEffect(() => {
    const checkTrustFeed = async () => {

      try {

        if (user?.lastLogin !== null && !isToday(user?.lastLogin)) {
          const isTrustFeedThere = await isTfAvailable();
          if (isTrustFeedThere?.available) {
            navigate('/trust');
          }
        }
      } catch (error) {
        console.error("Error checking Trust Feed availability:", error);
      }
    };

    if (user?.lastLogin) {
      checkTrustFeed();
    }
  }, [user?.lastLogin]);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [postTypeWheelOpen, setPostTypeWheelOpen] = useState(false)
  const [orgWheelOpen, setOrgWheelOpen] = useState(false)
  const [filterPostType, setFilterPostType] = useState('all')
  const [filterOrganization, setFilterOrganization] = useState('all')

  const { data, fetchNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      // queryKey: ['posts', filterPostType, filterOrganization],
      queryKey: ['getNioozeWrapper'],
      queryFn: getNioozeWrapper,
      initialPageParam: 1,
      getNextPageParam: getNextPageParam,
      refetchOnWindowFocus: false,
    })

  const isFilterSelected =
    filterPostType != 'all' || filterOrganization != 'all'

  let topics: Topic[] = []

  for (const page of data?.pages || []) {
    for (const item of page?.topics || []) {
      topics.push(item)
    }
  }

  const {
    ref: loadMoreRef,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      // console.log('🚀 ~ onChange ~ entry:', entry)
      if (inView) {
        fetchNextPage()
      }
    },
  })

  useEffect(() => {
    if (isFetching || topicsLoading) {
      setIsPageLoading(true)
    } else {
      // slight delay to avoid flickers.
      const timeout = setTimeout(() => setIsPageLoading(false), 200)
      return () => clearTimeout(timeout)
    }
  }, [isFetching, topicsLoading])

  return (
    <>
      <Header
        cb={() => {
          refetch()
        }}
      />
      <AnalyticsTrackerWrapper>
        <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
          {/* Left div */}
          <div
            className="min-h-44 overflow-hidden overflow-x-hidden mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          >
            <SidebarContent className="fixed" />
          </div>

          {/* Middle div */}
          <div
            className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 relative"
            style={{
              flex: '0 0 40%',
            }}
          >
            <div className="absolute -left-20  mx-desktop:left-3 top-0 h-full mx-mobile:h-fit">
              <div className="sticky mx-desktop:fixed mx-desktop:right-3 desktop:top-16 mx-desktop:bottom-3 z-30">
                <FilterWheelEntry
                  currentPostType={filterPostType}
                  setPostType={(type: string) => {
                    setFilterOrganization('all')
                    setFilterPostType(type)
                  }}
                  currentOrgType={filterOrganization}
                  setOrgType={(org: string) => {
                    setFilterPostType('all')
                    setFilterOrganization(org)
                  }}
                  // setFilterOrder={setFilterOrder}
                  // filterOrder={filterOrder}
                  // setFilterTime={setFilterTime}
                  // filterTime={filterTime}
                  postTypeWheelOpen={postTypeWheelOpen}
                  setPostTypeWheelOpen={setPostTypeWheelOpen}
                  orgWheelOpen={orgWheelOpen}
                  setOrgWheelOpen={setOrgWheelOpen}
                  // disabledPostTypes={disabledItems.postTypes}
                  // disabledOrgTypes={disabledItems.orgTypes}
                />
              </div>
            </div>

            {topics.map((topic) => {
              return (
                <TopicComponent
                  setTopicData={setTopicData}
                  key={topic.id}
                  topic={topic}
                  addPostsToRedux={addPostsToRedux}
                  filterPostType={filterPostType}
                  filterOrganization={filterOrganization}
                />
              )
            })}

            {!isPageLoading &&
              !isEmpty &&
              (isFilterSelected ? (
                <div className="mt-10 text-center text-[14px] text-border">
                  There are no posts in this filter
                </div>
              ) : (
                <TrendingTopics />
              ))}

            {/* {!isPageLoading && isEmpty && (
              <div className="mt-10 text-center text-[14px] text-border">
                No Posts
              </div>
            )} */}
            {!isPageLoading &&
              isEmpty &&
              (isFilterSelected ? (
                <div className="mt-10 text-center text-[14px] text-border">
                  There are no posts in this filter
                </div>
              ) : (
                <TrendingTopics />
              ))}

            {/* Loading */}

            {isPageLoading && (
              <div className="flex justify-center mt-2">
                <Loading />
              </div>
            )}

            {/* Intersection Observer for load more */}
            <div ref={loadMoreRef} className="h-5 w-full"></div>
          </div>

          {/* Right div */}
          <div
            className="relative min-h-44 mx-desktop:hidden"
            style={{
              flex: '0 0 30%',
            }}
          ></div>
        </div>
      </AnalyticsTrackerWrapper>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userError,
    permissions: state?.login?.user?.roles?.permissions,

    topics: state.topics.topics,
    trustFeedChangesState: state.trustFeed.trustFeedChanges,
    trustFeedContrastsState: state.trustFeed?.trustFeedContrasts,
    isTrustFeedSeen: state.trustFeed.isTrustFeedSeen,
    analyticsPosts: state.analytics.viewed_posts,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMe: () => {
      dispatch(getMe())
    },
    getAllTopics: () => {
      dispatch(getAllTopics())
    },
    selectPostDetails: (post: any) => {
      dispatch(selectPostDetails(post))
    },
    postEditRules: (postId: any) => {
      dispatch(postEditRules(postId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
    getTrustFeedChanges: (pagination:any) => {
      dispatch(getTrustFeedChanges(pagination))
    },
    trustFeedContrasts: () => {
      dispatch(trustFeedContrasts())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
