import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '../shadcn/dialog'
import { getPostsOfAThread } from '../../containers/Thread/helpers'
import PostWithContrast from '../Post/PostWithContrast'
import PostCard from '../Post/PostCard'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'
import { parsePostObjToProps } from '../Post/helpers'
import { alarmToggle, followUsers, upvoteToggle } from '../Post/services'
import { getUserFollowerIds } from '../../services/helpersApis'
import { X } from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import Loading from '../Loading'
import LoadingOverlay from '../LoadingOverlay'
import { useIdsStore } from '../Post/post.store'

type Props = {
  trigger?: ReactNode
  threadId: string
}

function Content({
  threadId,
  setOpen,
  open,
}: Props & { open: boolean; setOpen: (open: boolean) => void }) {
  // const [posts, setPosts] = useState([])

  const {
    isLoading,
    isFetching,
    error,
    data: posts,
    refetch,
  } = useQuery({
    queryKey: ['getPostsOfAThread', threadId],
    queryFn: async ({ queryKey }) => {
      const [key, threadId] = queryKey

      if (threadId) {
        // await new Promise((success) =>
        //   setTimeout(() => {
        //     success(null)
        //   }, 3000)
        // )
        return await getPostsOfAThread(threadId)
      }
    },
    initialData: [],
  })

  // const pageLoad = useCallback(() => {
  //   if (threadId) {
  //     getPostsOfAThread(threadId).then((data) => {
  //       setPosts(data)
  //     })
  //   }
  // }, [threadId])

  // useEffect(() => {
  //   pageLoad()
  // }, [pageLoad])

  const setUserFollowIds = useIdsStore((state: any) => state.setUserFollowIds)

  return (
    <div className="max-h-[80vh] mx-mobile:max-h-[90vh] px-20 mx-mobile:px-5 overflow-x-auto pt-8 pb-12">
      {isFetching && <Loading className="mx-auto" />}

      <div className="flex flex-col gap-4">
        {
          // !isFetching &&

          posts.map((post: any, i: number) => {
            const firstContrast = post?.con?.[0]
            const hasContrast = !!firstContrast

            return (
              <div className="">
                <PostCard
                  disableThreadButton
                  className="max-w-none"
                  {...parsePostObjToProps(post)}
                  onClickVote={() => {
                    upvoteToggle(post.id).then(() => {
                      refetch()
                    })
                  }}
                  onClickAlarm={() => {
                    alarmToggle(post.id).then(() => {
                      refetch()
                    })
                  }}
                  cb={refetch}
                  isFollowed={post?.isFollowed}
                  location={post?.postsLocationId}
                  setOpen={setOpen}
                />
                {hasContrast && (
                  <div className="flex">
                    <div className="flex flex-col items-center pr-[10px]">
                      <div className="h-[70px] w-[1px] bg-line" />

                      <img src={ContrastSVG} className="h-4 w-4" />
                    </div>
                    <div className="mt-4 w-full max-w-[460px]">
                      <PostCard
                        {...parsePostObjToProps(firstContrast)}
                        onClickVote={() => {
                          upvoteToggle(firstContrast.id).then(() => {
                            refetch()
                          })
                        }}
                        onClickAlarm={() => {
                          alarmToggle(firstContrast.id).then(() => {
                            refetch()
                          })
                        }}
                        cb={refetch}
                        blurProfile
                        isFollowed={post?.isFollowed}
                        location={post?.postsLocationId}
                        setOpen={setOpen}
                      />
                    </div>
                  </div>
                )}

                {/* <PostWithContrast post={post} key={i} pageLoad={pageLoad} /> */}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default function ThreadDialog(props: Props) {
  const { trigger } = props
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogClose className="bg-red-300" />
      <DialogContent
        className=" max-w-[590px] top-[100px] mx-mobile:top-6 translate-y-0 p-0 border-none gap-0"
        disableCloseButton
      >
        <div className="flex items-center justify-center px-20 bg-[#595e77] text-white rounded-t-md h-10">
          <p className=" font-semibold text-lg">Thread</p>
          {/* <DialogClose className="bg-red-200"></DialogClose> */}
          <X
            className="h-6 w-6 absolute right-[10px] cursor-pointer"
            strokeWidth={3}
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
        <Content {...props} open={open} setOpen={setOpen} />
      </DialogContent>
    </Dialog>
  )
}
