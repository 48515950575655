import React, { useEffect, useState } from 'react'
import { Dialog, DialogClose, DialogContent } from '../../../shadcn/dialog'
import { Input } from '../../../shadcn/input'
import { Textarea } from '../../../shadcn/textarea'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../shadcn/select'
import { PlusCircle, X } from 'lucide-react'
import Button from '../../../Button'
import PartyCard from './PartyCard'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { cn } from '../../../../utils/cn'
import UserSearchDialog from './UserSearchDialog'
import { notEmpty } from '../../../../utils/formHelpers'
import { createTopic, getTopicCategories } from '../../../../services/topic'
import { toast } from 'sonner'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import LocationSelect from '../../../LocationSelect/LocationSelect'
import { useSelector } from 'react-redux'
import LoadingOverlay from '../../../LoadingOverlay'

type DialogProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  cb?: () => void
}

export default function AddTopic(props: DialogProps) {
  const { open, setOpen } = props
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="max-h-screen max-w-[600px] overflow-y-auto px-0"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Content {...props} />
      </DialogContent>
    </Dialog>
  )
}

const schema = z.object({
  name: z.string().pipe(notEmpty),
  location: z.any(),
  category: z.string().pipe(notEmpty),
  description: z.string().pipe(notEmpty),
  parties: z.string().array(),
  moderators: z.string().array(),
})

type SchemaType = z.infer<typeof schema>

function Content({ setOpen, cb }: DialogProps) {
  const [partyOpen, setPartyOpen] = useState(false)
  const [moderatorOpen, setModeratorOpen] = useState(false)
  const [categories, setCategories] = useState<any[]>([])

  const currentUser = useSelector((state: any) => state.login.user)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getTopicCategories()
      .then((data) => {
        setIsLoading(false)

        setCategories(data)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      parties: [],
      moderators: [],
      location: {},
    },
  })

  useEffect(() => {
    const currentMods = getValues().moderators

    if (!currentMods.find((x) => x === currentUser.id)) {
      setValue('moderators', [...getValues().moderators, currentUser.id])
    }
  }, [currentUser])

  const parties = watch('parties')
  const moderators = watch('moderators')

  // const categories = [
  //   { label: 'Politics', value: 'politics' },
  //   { label: 'Business', value: 'business' },
  //   { label: 'Technology', value: 'technology' },
  //   { label: 'Entertainment', value: 'entertainment' },
  //   { label: 'Environment', value: 'environment' },
  //   { label: 'Health', value: 'health' },
  //   { label: 'Science', value: 'science' },
  //   { label: 'Lifestyle', value: 'lifestyle' },
  //   { label: 'Sports', value: 'sports' },
  // ]

  const [location, setLocation] = useState<any>()
  const [topicOpen, setTopicOpen] = useState<boolean>(false)
  const [locationObj, setLocationObj] = useState()

  useEffect(() => {
    setValue('location', location)
  }, [location])

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <form className="px-6">
        <p>
          Create{' '}
          <span className="font-bold underline underline-offset-4">TOPIC</span>
        </p>

        <div className="mt-5 flex flex-col gap-8">
          <div className="">
            <label className="text-[14px] font-bold">Topic Name*</label>
            <Input
              className="mt-1 h-[29px] border-line text-[14px] placeholder:text-[11px] placeholder:italic placeholder:text-line"
              placeholder="Name"
              {...register('name')}
            />
            {errors?.name && (
              <p className="text-[14px] font-semibold text-red-500">
                {errors?.name.message}
              </p>
            )}
          </div>

          <div className="">
            <label className="text-[14px] font-bold">Location*</label>
            <Popover open={topicOpen} onOpenChange={setTopicOpen}>
              <PopoverTrigger asChild>
                <button className={cn('mt-1 w-full cursor-pointer text-left')}>
                  {/*{!topic && !locationObj && (*/}
                  {location ? (
                    <div className="border-slate-300p-1 rounded-md border-2 p-1 text-sm text-black">
                      {location.label}
                    </div>
                  ) : (
                    <div className="border-slate-300p-1 rounded-md border-2 p-1 pl-3 text-[11px] italic text-gray-400 opacity-50">
                      Location
                    </div>
                  )}
                </button>
              </PopoverTrigger>
              <PopoverContent className="w-[500px] p-0 mx-sm:w-screen">
                <LocationSelect
                  // onSelectLocation={(value: any) => {
                  //   setLocation(value)
                  //   setTopicOpen(false)

                  //   // setValue('location', value)
                  // }}
                  // setLocationObj={setLocationObj}
                  locationFilterNeeded={false}
                  setLocation={(value: any) => {
                    setLocation(value)
                    setTopicOpen(false)

                    // setValue('location', value)
                  }}
                  // anyLevelOfLocation={true}
                />
              </PopoverContent>
            </Popover>
          </div>

          <div className="">
            <label className="text-[14px] font-bold">Category*</label>

            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <Select
                  onValueChange={(v) => {
                    onChange(v)
                  }}
                  value={value}
                >
                  <SelectTrigger
                    className={cn(
                      'mt-1 h-[29px] text-[11px] italic text-line',
                      {
                        'text-[14px] not-italic text-black': !!value,
                      }
                    )}
                  >
                    <SelectValue placeholder="Select" className="" />
                  </SelectTrigger>
                  <SelectContent>
                    {categories.map((x) => {
                      return (
                        <SelectItem value={x.id} key={x.id}>
                          {x.name}
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              )}
            />

            {errors?.category && (
              <p className="text-[14px] font-semibold text-red-500">
                {errors?.category.message}
              </p>
            )}
          </div>

          <div className="">
            <label className="text-[14px] font-bold">Description*</label>
            <Textarea
              className="mt-1 h-[29px] border-line text-[14px] placeholder:text-[11px] placeholder:italic placeholder:text-line"
              placeholder="Description"
              {...register('description')}
            />

            {errors?.description && (
              <p className="text-[14px] font-semibold text-red-500">
                {errors?.description.message}
              </p>
            )}
          </div>
        </div>
      </form>

      <div className="mt-5 flex h-[52px] items-center justify-between bg-line px-6">
        <span className="text-base font-semibold">Involved Parties</span>

        <UserSearchDialog
          title="Search Party"
          open={partyOpen}
          setOpen={setPartyOpen}
          trigger={<PlusCircle size={24} className="cursor-pointer" />}
          onSelectClick={(userId) => {
            // console.log(userId)
            const alreadyExists = !!parties.find((x) => x === userId)
            if (alreadyExists) {
              toast.error('Party already exists')
              setPartyOpen(false)
              return
            }
            const newArr = [...parties]
            newArr.push(userId)
            setValue('parties', newArr)
            setPartyOpen(false)
          }}
        />
      </div>

      <div className="mx-6 flex flex-col gap-[19px]">
        {parties.length < 1 && (
          <p className="text-[14px] text-border">No parties</p>
        )}
        {parties?.map((x: any) => {
          return (
            <PartyCard
              profileImageUrl={x?.imageUrl}
              userId={x}
              action={
                <X
                  size={20}
                  className="cursor-pointer transition-all hover:text-red-500"
                  onClick={() => {
                    const newArr = parties.filter((p) => p !== x)
                    setValue('parties', newArr)
                  }}
                />
              }
            />
          )
        })}
      </div>

      <div className="bg-line flex justify-between items-center px-6 h-[52px] mt-5">
        <span className="font-semibold text-base">Moderators</span>

        <UserSearchDialog
          title="Moderator search"
          onlyVerified
          open={moderatorOpen}
          setOpen={setModeratorOpen}
          trigger={<PlusCircle size={24} className="cursor-pointer" />}
          onSelectClick={(userId) => {
            const alreadyExists = !!moderators.find((x) => x === userId)

            if (alreadyExists) {
              toast.error('Moderator already exists')
              setPartyOpen(false)
              return
            }

            const newArr = [...moderators]
            newArr.push(userId)

            setValue('moderators', newArr)
            setModeratorOpen(false)
          }}
        />
      </div>

      <div className="mx-6 flex flex-col gap-[19px]">
        {moderators.length < 1 && (
          <p className="text-[14px] text-border">No moderators</p>
        )}
        {moderators?.map((x: any) => {
          return (
            <PartyCard
              profileImageUrl={x?.imageUrl}
              userId={x}
              action={
                <X
                  size={20}
                  className="cursor-pointer hover:text-red-500 transition-all"
                  onClick={() => {
                    if (x === currentUser.id) {
                      toast.error('Topic creator has to be a Moderator')
                      return
                    }
                    const newArr = moderators.filter((p) => p !== x)
                    setValue('moderators', newArr)
                  }}
                />
              }
            />
          )
        })}
      </div>

      {/* Actions */}
      <div className="mt-5 flex items-center justify-between px-6">
        <Button
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          type="submit"
          onClick={handleSubmit(async (value) => {
            try {
              await createTopic(value)
              setOpen(false)
              toast.success(`Topic: ${value.name} Created`)
              cb && cb()
            } catch (error) {
              console.log(error)
              toast.error('Something went wrong')
            }
          })}
        >
          Create
        </Button>
      </div>
    </>
  )
}
