import PostCard from '../Post/PostCard'
import { parsePostObjToProps } from '../Post/helpers'
import Spacer from '../../components/Spacer'
import upDown from '../../assets/icons/up-down-arrow.svg'
import dropDown from '../../assets/icons/drop-down-arrow.svg'
import React, { useEffect, useState } from 'react'
import { useIdsStore } from '../Post/post.store'
import { alarmToggle, followUsers } from '../Post/services'
import { getUserFollowerIds } from '../../services/helpersApis'

const TrustFeedChangesCard = ({ trustFeed, addPostsToRedux }: any) => {
  if (trustFeed?.isDeleted) {
    delete trustFeed?.editedPost?.author?.id
  }

  const trustFeedPost = trustFeed?.trustFeed
  const date = new Date(trustFeed?.post?.createdAt)

  const [moreDetails, setMoreDetails] = useState(false)

  const setUserFollowIds = useIdsStore((state: any) => state.setUserFollowIds)
  function onClickFollowUser(userId: any) {
    followUsers(userId).then(() => {
      //pageLoad()
      if (getUserFollowerIds) {
        getUserFollowerIds().then((userIds) => {
          setUserFollowIds(userIds)
        })
      }
    })
  }

  function onClickAlarmPost() {
    alarmToggle(trustFeed?.post?.id).then(() => {
      // pageLoad()
    })
  }

  return (
    <div className={'flex flex-col items-center justify-center mt-3'}>
      <div
        className={
          'w-[468px] h-[20px] bg-[#303655] content-center rounded-full '
        }
      >
        <p className={'text-white   text-[14px] font-bold text-center'}>
          {trustFeed?.visual}
        </p>
      </div>
      <p
        className={
          '  text-[10px] font-normal leading-[12.1px] text-center mt-1'
        }
      >
        {trustFeed?.sentence}
      </p>

      {trustFeed?.isTypeEdit && !trustFeed?.isTextEdit && (
        <>
          <div className={'w-[436px] pt-5'}>
            <PostCard
              postTypeForAnalytics={'trust changes'}
              {...parsePostObjToProps(trustFeed?.post)}
              oldPostType={trustFeed?.editedPost?.postTypeId}
              oldPostTypeLineThrough={true}
              addPostsToRedux={addPostsToRedux}
              disableArticleButton={true}
              disableActions={false}
            />
          </div>
          {/*<div className={'w-[436px] mt-6 hidden'}>*/}
          {/*    <PostCard*/}
          {/*        postTypeForAnalytics={'trust changes'}*/}
          {/*        postTypeLineThrough={true}*/}
          {/*        {...parsePostObjToProps(trustFeed?.post)}*/}
          {/*        addPostsToRedux={addPostsToRedux}*/}
          {/*        onClickFollowUser={onClickFollowUser}*/}
          {/*    />*/}
          {/*</div>*/}
        </>
      )}

      {trustFeed?.class?.flags?.isTypeEdit &&
        trustFeed?.class?.flags?.isTextEdit && (
          <>
            {' '}
            <div className={'w-[436px] pt-5 opacity-50 pointer-events-none'}>
              <PostCard
                postTypeForAnalytics={'trust changes'}
                {...parsePostObjToProps(trustFeed?.editedPost)}
                postTypeLineThrough={true}
                postContentLineThrough={true}
                addPostsToRedux={addPostsToRedux}
                disableArticleButton={true}
                disableActions={true}
              />
            </div>
            <div className={'w-[436px] mt-6'}>
              <PostCard
                postTypeForAnalytics={'trust changes'}
                {...parsePostObjToProps(trustFeed?.post)}
                addPostsToRedux={addPostsToRedux}
                //   onClickFollowUser={onClickFollowUser}
              />
            </div>
          </>
        )}
      {!trustFeed?.isTypeEdit && trustFeed?.isTextEdit && (
        <>
          {' '}
          <div className={'w-[436px] pt-5 opacity-50 pointer-events-none'}>
            <PostCard
              postTypeForAnalytics={'trust changes'}
              {...parsePostObjToProps(trustFeed?.editedPost)}
              addPostsToRedux={addPostsToRedux}
              postContentLineThrough={true}
              disableArticleButton={true}
              disableActions={true}
            />
          </div>
          <div className={'w-[436px] mt-6'}>
            <PostCard
              postTypeForAnalytics={'trust changes'}
              {...parsePostObjToProps(trustFeed?.post)}
              addPostsToRedux={addPostsToRedux}
              //   onClickFollowUser={onClickFollowUser}
            />
          </div>
        </>
      )}
      {trustFeed?.isDeleted && (
        <>
          {' '}
          <div className={'w-[436px] mt-6 hidden'}>
            <PostCard
              postTypeForAnalytics={'trust changes'}
              {...parsePostObjToProps(trustFeed?.post)}
              addPostsToRedux={addPostsToRedux}
              //   onClickFollowUser={onClickFollowUser}
            />
          </div>
          <div className={'w-[436px] pt-5 opacity-50 pointer-events-none'}>
            <PostCard
              postTypeForAnalytics={'trust changes'}
              {...parsePostObjToProps(trustFeed?.editedPost)}
              postContentLineThrough={true}
              disableArticleButton={true}
              postTypeLineThrough={true}
              addPostsToRedux={addPostsToRedux}
            />
          </div>
        </>
      )}
      {moreDetails && (
        <div className={' mt-[10px] w-[300px]'}>
          {/*Extra information*/}
          <p className={'text-[14px]'}>
            <b className={'text-[14px]'}>Topic:</b>{' '}
            {trustFeed?.topic ? trustFeed?.topic : 'Local News'}
          </p>
          <p className={'text-[14px]'}>
            <b className={'text-[14px]'}>Changed by:</b>{' '}
            {trustFeed?.post?.author?.name}
          </p>
          <p className={'text-[14px]'}>
            <b className={'text-[14px]'}>Changed on:</b>{' '}
            {`${String(date.getDate()).padStart(2, '0')}/${String(
              date.getMonth() + 1
            ).padStart(2, '0')}/${date.getFullYear()}`}
          </p>
          <p className={'text-[14px]'}>
            <b className={'text-[14px]'}>Reason:</b>{' '}
            <span className={'break-words'}>{trustFeed?.reason}</span>
          </p>
        </div>
      )}
      <div
        className={
          'cursor-pointer flex flex-row justify-center items-center mb-4 mt-0'
        }
        onClick={() => {
          setMoreDetails(!moreDetails)
        }}
      >
        {moreDetails ? (
          <>
            <span className={'text-[14px] text-[#9D9D9D] '}>Less</span>
            <img className={'h-[16px]'} src={upDown} alt="" />
          </>
        ) : (
          <>
            <span className={'text-[14px] text-[#9D9D9D] mt-1'}>More</span>
            <img className={'h-[16px]'} src={dropDown} alt="" />
          </>
        )}
      </div>
    </div>
  )
}
export default TrustFeedChangesCard
