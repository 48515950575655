import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../components-v2/shadcn/dialog'
import { notEmpty } from '../../utils/formHelpers'
import { useState } from 'react'
import { Input } from '../../components-v2/shadcn/input'
import Button from '../../components-v2/Button'
import { useSelector } from 'react-redux'
import { changePassword } from '../../services/auth'
import { toast } from 'sonner'

type DialogProps = {
  cb?: () => void
  trigger?: React.ReactNode
}

export default function ResetPasswordDialog(props: DialogProps) {
  const { trigger } = props
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="overflow-y-auto max-w-[400px] px-5 py-5 max-h-screen"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Content setOpen={setOpen} {...props} />
      </DialogContent>
    </Dialog>
  )
}

const schema = z
  .object({
    currentPassword: z.string().pipe(notEmpty),
    newPassword: z.string().min(6).pipe(notEmpty),
    confirmPassword: z.string().pipe(notEmpty),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })

type SchemaType = z.infer<typeof schema>

function Content({ cb, setOpen }: DialogProps & { setOpen: any }) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {},
  })

  // const currentUser = useSelector((state: any) => state.login.user)
  // console.log('🚀 ~ currentUser:', currentUser)

  return (
    <div>
      <form className="flex flex-col max-w-[450px] mx-auto">
        <h1 className="text-2xl font-bold mt-5">Set a new Password</h1>

        <div className="mt-6">
          <label className="text-[14px] font-bold">Old Password*</label>
          <Input
            type="password"
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Old Password"
            {...register('currentPassword')}
          />
          {errors?.currentPassword && (
            <p className="text-[14px] text-red-500 font-semibold">
              {errors?.currentPassword.message}
            </p>
          )}
        </div>

        <div className="mt-6">
          <label className="text-[14px] font-bold">New Password*</label>
          <Input
            type="password"
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="New Password"
            {...register('newPassword')}
          />
          {errors?.newPassword && (
            <p className="text-[14px] text-red-500 font-semibold">
              {errors?.newPassword.message}
            </p>
          )}
        </div>

        <div className="mt-6">
          <label className="text-[14px] font-bold">Confirm Password*</label>
          <Input
            type="password"
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Confirm Passsord"
            {...register('confirmPassword')}
          />
          {errors?.confirmPassword && (
            <p className="text-[14px] text-red-500 font-semibold">
              {errors?.confirmPassword.message}
            </p>
          )}
        </div>

        <div className="flex items-center justify-end mt-5">
          <Button
            // type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit(async (values) => {
              const payload = {
                ...values,
              }

              return changePassword(payload)
                .then((data) => {
                  toast.success('Password changed.')
                  setOpen(false)
                })
                .catch((err) => {
                  toast.error(
                    err?.response?.data?.message || 'Something went wrong'
                  )
                })
            })}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
}
