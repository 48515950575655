export const getEmbedUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url)

    switch (urlObj.hostname) {
      // YouTube
      case 'www.youtube.com':
      case 'youtube.com':
        // Check if it's a Shorts URL
        if (urlObj.pathname.startsWith('/shorts/')) {
          const shortId = urlObj.pathname.split('/')[2]
          return shortId ? `https://www.youtube.com/embed/${shortId}` : null
        }

        // Standard YouTube video
        const videoId = urlObj.searchParams.get('v')
        return videoId ? `https://www.youtube.com/embed/${videoId}` : null

      case 'youtu.be':
        return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`

      // Vimeo
      case 'vimeo.com':
        return `https://player.vimeo.com/video${urlObj.pathname}`

      // Dailymotion
      case 'www.dailymotion.com':
      case 'dailymotion.com':
        const dailymotionId = urlObj.pathname.split('/').pop()
        return dailymotionId
          ? `https://www.dailymotion.com/embed/video/${dailymotionId}`
          : null

      case 'dai.ly':
        return `https://www.dailymotion.com/embed/video${urlObj.pathname}`

      // Twitch
      // case 'www.twitch.tv':
      // case 'twitch.tv':
      //   const twitchPath = urlObj.pathname.split('/').filter(Boolean)
      //   if (twitchPath[0] === 'videos') {
      //     return `https://player.twitch.tv/?video=${twitchPath[1]}`
      //   } else if (twitchPath.length === 1) {
      //     return `https://player.twitch.tv/?channel=${twitchPath[0]}`
      //   }
      //   return null

      // TikTok
      case 'www.tiktok.com':
      case 'tiktok.com':
        return `https://www.tiktok.com/embed${urlObj.pathname}`

      // Facebook (Videos)
      case 'www.facebook.com':
      case 'facebook.com':
        if (url.includes('/videos/')) {
          return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
            url
          )}`
        }
        return null

      // add https://mast-7161.eduvision.tv/Default
      // Unsupported platform
      default:
        return null
    }
  } catch (error) {
    console.error('Invalid URL:', error)
    return null
  }
}

// Example Usage
console.log(getEmbedUrl('https://www.youtube.com/shorts/FeZUkIkflFQ'))
// Outputs: https://www.youtube.com/embed/FeZUkIkflFQ
