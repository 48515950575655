import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { resendEmailVerification } from '../../services/auth'
import { toast } from 'sonner'
import { cn } from '../../utils/cn'
import Button from '../../components-v2/Button'
import { getMe } from '../../redux/login/actions'
import Header from '../../components-v2/Header/Header'
import { isAuthenticated } from '../../utils/auth'

type Props = {}

export default function EmailUnverified({}: Props) {
  const [sendingEmail, setSendingEmail] = useState(false)
  // const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state: any) => state.login.user)

  const emailVerified = user?.emailVerified
  useEffect(() => {
    getMe()(dispatch)
  }, [dispatch])

  useEffect(() => {
    if (
      typeof user?.emailVerified === 'boolean' &&
      user?.emailVerified === true
    ) {
      navigate('/')
    }
  }, [emailVerified])

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login')
    }
  }, [])

  return (
    <>
      <Header
      // cb={() => {
      //   refetch()
      // }}
      />
      <div className="w-full mx-auto flex flex-col items-center justify-center mt-10">
        <p className="text-[14px] font-semibold">Please verify your email.</p>
        {user && (
          <p className="text-[14px] text-gray-600">
            An email was sent to {user?.email}
          </p>
        )}
        <Button
          className="mt-2"
          disabled={sendingEmail}
          onClick={() => {
            setSendingEmail(true)
            resendEmailVerification()
              .then(() => {
                toast.success('Verification email is on the way.')
              })
              .finally(() => {
                setSendingEmail(false)
              })
          }}
        >
          {/* {sendingEmail ? <Loading /> : 'Resend email'} */}
          Resend email
        </Button>
      </div>
    </>
  )
}
