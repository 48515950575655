import moment from 'moment'
import LinkSVG from '../../assets/icons/fi-rr-link.svg'
import { cn } from '../../utils/cn'
import TooltipComp from '../Tooltip'

export function parsePostObjToProps(postObj: any) {
  return {
    postId: postObj?.id,
    postType: postObj?.postTypeId,
    type: postObj?.type,
    author: postObj?.author?.name,
    authorId: postObj?.author?.id,
    authorProfileUrl: postObj?.author?.imageUrl,
    authorRole: postObj?.author?.roles?.name,
    authorIsVerified: postObj?.author?.verified,
    authorIsPublic: postObj?.author?.public,
    organizationProfileUrl: postObj?.organization?.imageUrl,
    organizationName: postObj?.organization?.name,
    organizationType: postObj?.organization?.type,
    organizationId: postObj?.organization?.id,
    upvoteCount: postObj?.upvote_count,
    alarms: postObj?.alarm,
    content: postObj?.description,
    mediaUrl: postObj?.mediaId,
    video_url: postObj?.video_url,
    articleId: postObj?.articleId,
    contrastCount: postObj?.contrast_count,
    commentCount: postObj?.comment_count,
    createdAt: postObj?.createdAt,
    quoteData: postObj?.quoteData,
    topicId: postObj?.topicId,
    location: postObj?.postLocations?.id ? postObj?.postLocations?.id : null,
    address: postObj?.address,
    isFollowed: postObj?.isFollowed,
    moderators: postObj?.topic?.moderators,
    threadId: postObj?.thread_id,
    threadOrder: postObj?.thread_order,
    threadTotal: postObj?.thread_total,
  }
}

function getDomainFromUrl(url: string) {
  // Remove protocol
  let domain = url.replace(/^https?:\/\//, '')
  // Remove www
  domain = domain.replace(/^www\./, '')
  // Get domain name
  domain = domain.split('/')[0]
  return domain
}

// const urlRegex = /(https?:\/\/[^\s]+)/g
const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/gi
export function stringHasLink(text: string) {
  return text.match(urlRegex)
}

export function contentToJSX({
  content,
  collapsedURL = true,
}: {
  content: string
  collapsedURL?: boolean
}) {
  const lines = content.split('\n') // Split text by line breaks

  return lines.map((line, index) => {
    // Check if the line contains URLs
    if (line.match(urlRegex)) {
      // Split the line by URLs
      const lineParts = line.split(urlRegex)
      // console.log('🚀 ~ returnlines.map ~ lineParts:', lineParts)

      return (
        <p key={index} className="break-words">
          {lineParts.map((part, index) => {
            // Return link JSX element if part is a URL
            if (part.match(urlRegex)) {
              if (collapsedURL) {
                return (
                  <span key={index} className={cn('w-fit italic')}>
                    <img src={LinkSVG} className="inline h-[13px] w-[13px]" />{' '}
                    {getDomainFromUrl(part)}
                  </span>
                )
              } else {
                return (
                  <a
                    key={index}
                    href={part}
                    target="_blank"
                    className={cn(
                      'w-fit rounded-md py-[2px] italic transition-all hover:underline'
                    )}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <img src={LinkSVG} className="inline h-[13px] w-[13px]" />{' '}
                    {/* <span>{collapsedURL ? getDomainFromUrl(part) : part}</span> */}
                    {/* // <TooltipComp
                      //   trigger={
                      //     <span className="inline ml-[2px]">
                      //       {getDomainFromUrl(part)}
                      //     </span>
                      //   }
                      //   tooltip={
                      //     <div className="max-w-80 text-[#2443B2]">{part}</div>
                      //   }
                      //   contentClassName="text-[14px] not-italic"
                      // /> */}
                    <span className="break-words text-[#2443B2]">{part}</span>
                  </a>
                )
              }
            }
            // Otherwise, return the text part
            return part
          })}
        </p>
      )
    } else {
      // If the line doesn't contain URL return the line
      // If line is empty return a <br /> since the <p> tag wont break the line if the innerHtml is empty
      return <p key={index}>{line || <br />}</p>
    }
  })
}

export function getHowLongAgo(date: Date | string) {
  const now = moment()

  const pastDate = moment(date)

  const duration = moment.duration(now.diff(pastDate))

  const years = duration.years()
  const months = duration.months()

  const hours = duration.hours()
  const days = duration.days()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  let result = ''
  if (years > 0) {
    result += years + 'y'
    return result
  }
  if (months > 0) {
    result += months + 'mo'
    return result
  }
  if (days > 0) {
    result += days + 'd'
    return result
  }
  if (hours > 0) {
    result += hours + 'h'
    return result
  }
  if (minutes > 0) {
    result += minutes + 'm'
    return result
  }

  return 'Just now'
}

const filterAddressProperties = (address: any, propertiesToDelete: any) => {
  const filteredAddress = { ...address }

  // Delete unwanted properties
  propertiesToDelete.forEach((property: string | number) => {
    if (filteredAddress.hasOwnProperty(property)) {
      delete filteredAddress[property]
    }
  })

  return filteredAddress
}

export const getLocationToDisplay = (
  postLocation: any,
  propertiesToDelete: any,
  setLocationToDisplayUnderThePost: any
) => {
  if (postLocation != null) {
    const flitteredLocation = filterAddressProperties(
      postLocation,
      propertiesToDelete
    )

    let valuesArray = Object.values(flitteredLocation)
    if (
      Object.values(postLocation).length === 4 ||
      Object.values(postLocation).length === 5
    ) {
      //if only has city
      setLocationToDisplayUnderThePost(valuesArray[0])
    } else if (Object.values(postLocation).length > 5) {
      //county
      setLocationToDisplayUnderThePost(valuesArray[0] + ', ' + valuesArray[1])
    } else if (Object.values(postLocation).length < 4) {
      setLocationToDisplayUnderThePost(valuesArray[0])
    }
  }
}

export const getInitials = (name: any) => {
  const words = name?.split(/[\s.]+/).filter((word: any) => word.length > 0)

  if (words?.length === 1) {
    return words[0].charAt(0).toUpperCase()
  }

  return words?.map((word: any) => word.charAt(0).toUpperCase()).join('')
}

export const locationToDisplay = (address: object) => {
  let valuesArray = Object?.values(address)
  if (Object?.values(address)?.length === 5) {
    return valuesArray[0] + ', ' + valuesArray[1]
  } else {
    return valuesArray[0]
  }
}
