import { FieldError } from 'react-hook-form'
import { cn } from '../utils/cn'

type Props = {
  error?: FieldError | any
  className?: string
}

export default function RenderFieldError({ error, className }: Props) {
  if (error?.message) {
    return (
      <p className={cn('text-[14px] text-red-500 font-semibold', className)}>
        {error?.message}
      </p>
    )
  } else {
    return null
  }
}
