import PostCard from '../Post/PostCard'
import { parsePostObjToProps } from '../Post/helpers'
import { alarmToggle, upvoteToggle } from '../Post/services'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'
import {useNavigate} from "react-router-dom";

const TrustFeedContrasts = ({ trustFeedContrasts, addPostsToRedux }: any) => {

  const navigate = useNavigate()

  return (
    <>
      {trustFeedContrasts?.map((trustFeedContrast: any) => {
        return (
          <div className={'ml-4 m-4'}>
               <div className={'flex justify-center'}>
                 <PostCard
                     postTypeForAnalytics="trust contrast"
                     {...parsePostObjToProps(trustFeedContrast)}
                     onClickVote={() => upvoteToggle(trustFeedContrast?.id)}
                     onClickAlarm={() => alarmToggle(trustFeedContrast?.id)}
                     addPostsToRedux={addPostsToRedux}
                 />
               </div>
            <div className={'flex flex-row justify-center  mt-2'}>
              <div>
                <div className="w-[1px] bg-line h-[70px] ml-[7px]"/>
                <img src={ContrastSVG} className="w-4 h-4 mt-1 "/>
              </div>
              <div className={'ml-1 w-[415px]'}>
                {trustFeedContrast?.con?.map((contrast: any) => {
                  return (

                        <PostCard
                            postTypeForAnalytics="trust contrast"
                            {...parsePostObjToProps(contrast)}
                            onClickVote={() => upvoteToggle(contrast?.id)}
                            // onClickVote={onClickVoteContrast}
                            onClickAlarm={() => alarmToggle(contrast?.id)}
                            // cb={pageLoad}
                            addPostsToRedux={addPostsToRedux}
                        />

                  )
                })}
                {
                    trustFeedContrast?.moreCon && <div onClick={ () => navigate(`/topic/${
                        trustFeedContrast?.topicId === null ? trustFeedContrast?.postsLocationId : trustFeedContrast?.topicId
                    }/post/${trustFeedContrast?.id}`)} className="mt-2 text-center text-[14px] text-border cursor-pointer">
                      show more unseen
                      contrasts
                    </div>
                }
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TrustFeedContrasts
