import React, { useEffect } from 'react'
import PostCard from '../../../components-v2/Post/PostCard'
import {
  getNotifications,
  readFromSection,
} from '../../../services/notifications'
import { useQuery } from '@tanstack/react-query'
import Loading from '../../../components-v2/Loading'
import { useNotifications } from '../notifications.store'
import {
  getHowLongAgo,
  parsePostObjToProps,
} from '../../../components-v2/Post/helpers'

type Props = {}

export default function CommentsTab({}: Props) {
  const loadSummary = useNotifications((state: any) => state.loadSummary)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      readFromSection('comments').then(() => {
        loadSummary()
      })
    }, 2000)

    return () => clearTimeout(timeoutId)
  }, [])

  const { isLoading, error, data } = useQuery({
    queryKey: ['getNotifications', 'comments'],
    queryFn: getNotifications,
    initialData: [],
  })

  return (
    <div className=" flex flex-col items-center pb-10">
      {isLoading && <Loading />}

      {data.length < 1 && <p className="text-[14px] text-border">Empty.</p>}

      {data.map((x: any) => {
        const time = getHowLongAgo(x?.createdAt)
        return (
          <div className="w-full flex flex-col items-center pt-2 pb-4 pr-3 pl-3">
            <div className="max-w-[434px] w-full mb-2 text-[14px]">
              <p>
                <span className="font-bold">{x?.actingUser?.name}</span>{' '}
                commented on your post{' '}
                {time === 'Just now' ? time : `${time} ago`}.
              </p>
            </div>
            <PostCard {...parsePostObjToProps(x.relatedPost)} />
          </div>
        )
      })}
    </div>
  )
}
