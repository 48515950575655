import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export function useEmailVerificationChecker() {
  const user = useSelector((state: any) => state.login.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      typeof user?.emailVerified === 'boolean' &&
      user?.emailVerified === false
    ) {
      navigate('/verify-email')
    }
  }, [user?.emailVerified])
}
