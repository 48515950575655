import { Film, XCircle, XIcon } from 'lucide-react'
import React, { useState } from 'react'
import {
  Control,
  Controller,
  useController,
  useFormContext,
} from 'react-hook-form'
import { Textarea } from '../../../shadcn/textarea'
import { PostSchemaType, SchemaType } from './helper'
import ImageUploadIcon from '../../../ImageUploadIcon'
import RenderFieldError from '../../../RenderFieldError'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../shadcn/popover'
import { cn } from '../../../../utils/cn'
import { useUpload } from '../../../../utils/uploadImage'
import LandscapeSVG from '../../../../assets/icons/landscape.svg'
import UploadProgress from '../../../UploadProgress'
import VideoEmbedDialog from '../../../VideoEmbedDialog/VideoEmbedDialog'

type Props = {
  index: number
  onClickClose: (index: number) => void
  allowedPostTypes: string[]
}

export default function PostInputField({
  index,
  onClickClose,
  allowedPostTypes,
}: Props) {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors: err },
  } = useFormContext<SchemaType>()

  const [typePopoverOpen, setTypePopoverOpen] = useState(false)

  const errors = err?.posts?.[index] as any

  const imageUrl = watch(`posts.${index}.data.imageUrl`)
  const videoUrl = watch(`posts.${index}.data.videoUrl`)
  const postType = watch(`posts.${index}.data.postType`)
  const text = watch(`posts.${index}.data.text`)

  const {
    upload,
    progress,
    url: postImageUrl,
    setUrl: setPostImageUrl,
    totalBytes,
    transferedBytes,
  } = useUpload()

  // console.log('🚀 ~ errors:', errors)
  return (
    <div className="flex mt-5">
      <div className="w-5 text-[14px] font-semibold -ml-5">{index + 1}.</div>

      <div className="flex-1">
        <div className="flex justify-between">
          <Controller
            name={`posts.${index}.data.postType`}
            control={control}
            render={({ field }) => {
              return (
                <Popover
                  open={typePopoverOpen}
                  onOpenChange={setTypePopoverOpen}
                >
                  <PopoverTrigger>
                    <div className="text-[14px] font-semibold uppercase flex gap-[3px]">
                      <p className="">{postType}</p>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent
                    align="start"
                    className="text-[14px] py-2 px-[2px] w-fit"
                  >
                    {allowedPostTypes.map((x) => {
                      return (
                        <div
                          onClick={() => {
                            field.onChange(x)
                            setTypePopoverOpen(false)
                          }}
                          key={x}
                          className="text-[14px] font-semibold uppercase flex gap-[3px] px-2 py-[1px] hover:bg-line rounded transition-all cursor-pointer"
                        >
                          <p className="">{x}</p>
                        </div>
                      )
                    })}
                  </PopoverContent>
                </Popover>
              )
            }}
          />

          <div
            className="w-[18px] h-[18px] hover:bg-line transition-all flex items-center justify-center rounded-md cursor-pointer"
            onClick={() => onClickClose(index)}
          >
            <XIcon size={20} />
          </div>
        </div>

        <Textarea
          // TODO: Change placeholder acording to the post type
          placeholder="Content"
          className="h-[29px] bg-line border-line placeholder:text-border placeholder:text-[11px] placeholder:italic mt-[2px] text-[14px]"
          {...register(`posts.${index}.data.text`)}
        />

        <RenderFieldError error={errors?.text} />

        {/* {errors?.text && (
          <p className="text-[14px] text-red-500 font-semibold">
            {errors?.text.message}
          </p>
        )} */}

        {imageUrl && (
          <div className="flex flex-col gap-1 w-full">
            <div
              className="w-[18px] h-[18px] hover:bg-line transition-all flex items-center justify-center rounded-md cursor-pointer self-end"
              onClick={() => setValue(`posts.${index}.data.imageUrl`, '')}
            >
              <XIcon size={20} />
            </div>
            <img src={imageUrl} className="object-cover rounded-md w-full" />
          </div>
        )}

        {videoUrl && (
          <div className="flex flex-col gap-1 w-full">
            <div
              className="w-[18px] h-[18px] hover:bg-line transition-all flex items-center justify-center rounded-md cursor-pointer self-end"
              onClick={() => setValue(`posts.${index}.data.videoUrl`, '')}
            >
              <XIcon size={20} />
            </div>

            <iframe
              className="w-full aspect-video rounded-md"
              src={videoUrl}
              title="Embedded Video"
              allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )}

        {progress > 0 && (
          <UploadProgress
            progress={progress}
            transferedBytes={transferedBytes}
            totalBytes={totalBytes}
          />
        )}

        <div className="flex justify-between items-center mt-[16px]">
          <div className="flex">
            {!videoUrl && (
              <Controller
                control={control}
                name={`posts.${index}.data.imageUrl`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <label
                      className={cn(
                        'p-[3px] rounded-md transition-all cursor-pointer hover:bg-line '
                      )}
                    >
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(event) => {
                          const image = event.target.files?.[0]
                          if (image) {
                            upload(image, 'user-images', onChange)
                          }
                        }}
                      />
                      <img src={LandscapeSVG} className="w-[16px] h-[16px] " />
                    </label>
                  )
                }}
              />
            )}

            {!imageUrl && (
              <Controller
                control={control}
                name={`posts.${index}.data.videoUrl`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <VideoEmbedDialog
                      trigger={
                        <div
                          className={cn(
                            'cursor-pointer rounded-md transition-all hover:bg-line h-[22px] w-[22px] flex items-center justify-center'
                          )}
                        >
                          <Film size={18} className="text-gray-600" />
                        </div>
                      }
                      onSet={onChange}
                    />
                  )
                }}
              />
            )}
          </div>

          <p
            className={cn('text-[14px] text-border', {
              'text-red-500': text?.length > 300,
            })}
          >
            {text?.length || 0}/300
          </p>
        </div>
      </div>
    </div>
  )
}
