import React, { useState, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import './index.css'

import { addUsersForOrganization } from '../../redux/organizations/actions'

import Spacer from '../../components/Spacer'
import OnBoardButton from '../../components/OnBoardButton'
import ProfileBadgeWithLocationAndOrg from '../../components/ProfileBadgeWithLocationAndOrg'

import dropDown from '../../assets/icons/drop-down.svg'
import icon from '../../assets/icons/icon.svg'
import lens from '../../assets/icons/lens.svg'
import { BeatLoader } from 'react-spinners'
import debounce from '../../utils/debounce'
import { searchUsers } from '../../services/user'
import { User } from '../../types/user'
import PartyCard from '../../components-v2/EntryDialog/dialogs/AddTopic/PartyCard'
import { Input } from '../../components-v2/shadcn/input'
import Loading from '../../components-v2/Loading'
import Button from '../../components-v2/Button'

const popover = (
  <Popover style={{ width: '300px' }}>
    <Popover.Header as="h3">Popover right</Popover.Header>
    <Popover.Body>
      <b>Admin</b>
      <ul>
        <li>Can edit the profile of an organization</li>
        <li>Can add/remove members of an organization</li>
        <li>Can leave an organization him/herself;</li>
        <li>Can contribute content as a member of that organization.</li>
      </ul>
    </Popover.Body>
  </Popover>
)

const AddMemberToOrgModal = ({
  addUsersForOrganization,
  organizationId,
  setAddMemberToOrgModalVisible,
  addMemberToOrgModalVisible,
  addUserForOrganizationLoading,
                               organizationName
}: any) => {
  const [userForOrg, setUserForOrg] = useState([])
  const [selectedUser, setSelectedUser] = useState<any>() //selected user to be added to the organization
  const [showRoleList, setShowRoleList] = useState(false)
  const [showOverlay, setShowOverLay] = useState(false)

  const [selectRole, setSelectRole] = useState('Admin') //selected role for organization
  const [searchUserLoading, setSearchUserLoading] = useState(false)

  const roleSelector = useRef(null)

  const onSelectRole = (role: any) => {
    //this will fire when use click on drop down
    setSelectRole(role)
    setShowRoleList(!showRoleList)
  }
  const onAddUserForOrganization = () => {
    addUsersForOrganization(selectedUser?.id, selectRole, organizationId)
    setAddMemberToOrgModalVisible(!addMemberToOrgModalVisible)
  }

  const [fieldValue, setFieldValue] = useState('')
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)
  const debouncedOnChange = useCallback(
    debounce((value) => {
      // console.log('Input value:', value)
      if (value) {
        setLoading(true)
        searchUsers({
          text: value,
        })
          .then((data) => {
            setUsers(data)
            // if (data.length <= 0) {
            //   setShowCustomCreate(true)
            // }
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setUsers([])
      }
    }, 300),
    []
  )

  return (
    <div className={'main-div-add-member'}>
      <div className={'second-main-div'}>
        <div style={{ background: '#FBFBFD' }}>
          <div className={'flex-style-heading'}>
            <p className={'body-text-style-add-member'}>{organizationName}</p>
          </div>
          <div style={{ marginTop: '40px', marginBottom: '130px' }}>
            <div
              style={{
                overflowX: 'hidden',
                maxWidth: '100%',
                // position: 'absolute',
              }}
            >
              {!selectedUser && (
                <div className={'overflow-y-auto'}>
                  <Input
                    className="mt-5 h-[29px] w-[312px] border-line text-[14px] placeholder:text-[11px] placeholder:italic placeholder:text-line"
                    placeholder="Name"
                    onChange={(event) => {
                      debouncedOnChange(event.target.value)
                    }}
                  />

                  {loading && (
                    <div className="mt-2 flex justify-center">
                      <Loading />
                    </div>
                  )}

                  <div className="mt-5 flex flex-col gap-[19px]">
                    {users.map((x: any) => {
                      return (
                        <PartyCard
                          name={x.name}
                          roleName={x.roles?.name}
                          profileImageUrl={x?.imageUrl}
                          location={x?.userLocations?.name}
                          organization={x?.organizationUsers?.[0]?.name}
                          onClickCard={() => {
                            // onSelectClick && onSelectClick(x?.id)
                            setSelectedUser(x)
                          }}
                          isVerified={x?.verified}
                          isPublic={x?.public}
                        />
                      )
                    })}
                  </div>
                </div>
              )}

              {
                //show when user select user from drop down
                selectedUser && (
                  <div>
                    <div className={'profile-main-add-member'}>
                      {/*Selected member's profile start*/}
                      <div style={{ display: 'flex' }}>
                        <img
                          src={selectedUser?.imageUrl}
                          className={'profile-picture-add-member'}
                          alt={''}
                        />
                        <Spacer width={20} />
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <text className={'name-add-member capital-letter'}>
                            {selectedUser?.name}
                          </text>
                          <text className={'location-add-member'}>
                            {selectedUser?.roles?.name}
                          </text>
                        </div>
                        <Spacer width={15} />
                      </div>
                    </div>{' '}
                    {/*Selected member's profile end*/}
                    <div className={'drop-down-role-div'}>
                      <div>
                        <span className={'make-bold-letter'}>Role</span>
                      </div>
                      <img
                        id="overlay"
                        src={icon}
                        alt="icon"
                        onClick={() => {
                          setShowOverLay(!showOverlay)
                        }}
                      />
                      {showOverlay && (
                        <div className={'overlay-div'}>
                          <h5 style={{ paddingLeft: '3px' }}>Admin</h5>
                          <ul className={'padding-left-add-member'}>
                            <li>Can edit the profile of an organization;</li>
                            <li>Can add/remove members of an organization;</li>
                            <li>Can leave an organization him/herself;</li>
                            <li>
                              Can contribute content as a member of that
                              organization.
                            </li>
                          </ul>
                          <br />
                          <h5 style={{ paddingLeft: '13px' }}>Member</h5>
                          <ul className={'padding-left-add-member'}>
                            <li>
                              Can contribute content as a member of that
                              organization.
                            </li>
                            <li>Can leave an organization</li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <label>
                      {' '}
                      {/*disapear when user select item from drop down*/}
                      <input
                        placeholder="Search User"
                        name="copy-button"
                        className={
                          'role-add-drop-down-add-member capital-letter'
                        }
                        onClick={(e) => setShowRoleList(!showRoleList)}
                        ref={roleSelector}
                        value={selectRole}
                      />
                      <img id="icon" src={dropDown} alt="icon" />
                    </label>
                    {showRoleList && (
                      <div className={'drop-down-list-style-add-member'}>
                        {/*disapear when user select item from drop down*/}
                        {['Admin', 'Member']?.map((role: any) => {
                          return (
                            <p
                              style={{
                                textAlign: 'start',
                                paddingLeft: '8px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                              }}
                              onClick={() => onSelectRole(role)}
                              className={
                                'drop-down-hover-list-style-add-member capital-letter'
                              }
                            >
                              {role}
                            </p>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          </div>
          <div>
            <OnBoardButton
              onClick={() => onAddUserForOrganization()}
              width={'312px'}
              buttonName={'Confirm'}
              loading={addUserForOrganizationLoading}
              disabled={false}
            />
            <Spacer height={8} />
            <OnBoardButton
              onClick={() =>
                setAddMemberToOrgModalVisible(!addMemberToOrgModalVisible)
              }
              width={'312px'}
              buttonName={'Cancel'}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    addUserForOrganizationLoading:
      state.organization.addUserForOrganizationLoading,
    addUserForOrganizationError:
      state.organization.addUserForOrganizationLoading,
    organizationId: state.organization.organization?.id,
    organizationName: state.organization.organization?.name,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUsersForOrganization: (
      organizationMemberId: string,
      role: string,
      organizationId: string
    ) => {
      dispatch(
        addUsersForOrganization(organizationMemberId, role, organizationId)
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberToOrgModal)
