import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PostWithContrast from '../../../components-v2/Post/PostWithContrast'
import { getPostsOfATopic, getPostsOfALocalTopic } from '../../../services/post'
import Loading from '../../../components-v2/Loading'
import { filterConsecutiveCons } from '../../../utils/postHelper'
// import { useUserStore } from '../../UserScreen-v2/user.store'
import { getUserFollowerIds } from '../../../services/helpersApis'

type Props = {
  posts: any[]
  isLoading?: boolean
  cb: () => void
  onNoAuthCallback?: () => void
  analyticsPosts?: any
  addPostsToRedux?: () => void
  isFilterSelected?: boolean
}

export default function PostsTab({
  posts = [],
  cb,
  isLoading = false,
  onNoAuthCallback,
  analyticsPosts,
  addPostsToRedux,
  isFilterSelected,
}: Props) {
  const { topicId } = useParams<{ postId: string; topicId: string }>()
  // const [isLoading, setIsLoading] = useState(false)
  // const [posts, setPosts] = useState([])
  // console.log('🚀 ~ PostsTab ~ posts:', posts)

  // const pageLoad = useCallback(() => {
  //   if (topicId) {
  //     if (topicId === 'local') {
  //       //if the topic is local
  //       setIsLoading(true)
  //       getPostsOfALocalTopic()
  //         .then((data) => {
  //           setPosts(data)
  //         })
  //         .finally(() => {
  //           setIsLoading(false)
  //         })
  //     } else {
  //       //if it is not a local topic
  //       setIsLoading(true)
  //       getPostsOfATopic(topicId)
  //         .then((data) => {
  //           setPosts(data)
  //         })
  //         .finally(() => {
  //           setIsLoading(false)
  //         })
  //     }
  //   }
  // }, [topicId])

  // useEffect(() => {
  //   pageLoad()
  // }, [pageLoad])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'auto',
  //   })
  // }, [])
  // const setUserFollowIds = useUserStore((state: any) => state.setUserFollowIds)
  // useEffect(() => {
  //   getUserFollowerIds().then((userIds: any) => {
  //     setUserFollowIds(userIds)
  //   })
  // }, [getUserFollowerIds])

  const filteredPosts = filterConsecutiveCons(posts)

  return (
    <>
      {/* {isLoading && (
        <div className="mt-5">
          <Loading />
        </div>
      )} */}
      {!isLoading && (
        <div className="flex w-full flex-col gap-6">
          {filteredPosts.length > 0 ? (
            filteredPosts.map((contrast: any, i: number) => (
              <PostWithContrast
                post={contrast}
                key={i}
                pageLoad={cb}
                onNoAuthCallback={onNoAuthCallback}
                analyticsPosts={analyticsPosts}
                addPostsToRedux={addPostsToRedux}
                isLocationModerator={contrast?.isLocationModerator}
              />
            ))
          ) : isFilterSelected ? (
            <div className="mt-1 text-center text-[14px] text-border">
              There are no posts in this filter
            </div>
          ) : (
            <div className="mt-8 text-center text-[14px] text-border">
              No Posts
            </div>
          )}
        </div>
      )}
    </>
  )
}
