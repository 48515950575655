import React from 'react'
import css from './Loading.module.css'
import { cn } from '../utils/cn'

type Props = {
  className?: string
}

export default function Loading({ className }: Props) {
  return (
    // <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-border" />
    <div className={cn(css.loader, className)} />
  )
}
