import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { z } from 'zod'
import { useSignupFormData } from '../signupFormData.store'
import Button from '../../../components-v2/Button'
import { Input } from '../../../components-v2/shadcn/input'
import { notEmpty } from '../../../utils/formHelpers'
import Loading from '../../../components-v2/Loading'
import { useNavigate } from 'react-router-dom'
import CheckLogoSVG from '../../../assets/icons/check-logo.svg'
import { useRedirect } from '../useRedirect'
import { doesEmailExist } from '../../../services/auth'
import { toast } from 'sonner'

type Props = {}

const schema = z.object({
  email: z.string().email().pipe(notEmpty),
  password: z.string().pipe(notEmpty),
})
// .refine(
//   async (data) => {
//     const isValid = await doesEmailExist(data.email)
//     console.log('🚀 ~ isValid:', isValid)
//     return isValid
//   },
//   {
//     message: 'Username failed the asynchronous check',
//     path: ['username'], // Path to the field being validated
//   }
// )

type SchemaType = z.infer<typeof schema>

export default function EmailTab({}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })

  const zSetValue = useSignupFormData((state: any) => state.setValue)

  const navigate = useNavigate()

  return (
    <div className="  min-h-screen bg-primary text-white">
      <form
        className="mx-auto mb-20 mt-[94px] max-w-[300px]"
        onSubmit={handleSubmit(async (values) => {
          zSetValue('email', values.email)
          zSetValue('password', values.password)

          const isValid = await doesEmailExist(values.email)
          if (isValid) {
            navigate('/signup/profile')
          } else {
            toast.error('Email exists')
          }
        })}
      >
        <img src={CheckLogoSVG} className="mx-auto w-[200px] text-[200px]" />
        <h1 className="mx-auto mt-8 text-center text-3xl font-bold">niooze</h1>

        <div className="mt-[50px] font-bold">
          <p>
            Complete your <br /> login details
          </p>
        </div>

        <div className="pt-[40px]">
          <p className="mb-[6px] text-[14px] font-bold">Email address*</p>
          <Input
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            placeholder="Email"
            {...register('email')}
          />
          {errors?.email && (
            <p className="mt-1 text-[14px] font-bold text-red-500">
              {errors?.email?.message}
            </p>
          )}
        </div>

        <div className="pt-[40px]">
          <p className="mb-[6px] text-[14px] font-bold">Password*</p>
          <Input
            type="password"
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            placeholder="Password"
            {...register('password')}
          />
          {errors?.password && (
            <p className="mt-1 text-[14px] font-bold text-red-500">
              {errors?.password?.message}
            </p>
          )}
        </div>

        <div className="mt-[90px] flex flex-col items-center">
          <Button
            disabled={isSubmitting}
            type="submit"
            className="flex w-full items-center justify-center gap-2 text-black transition-all"
          >
            Continue {isSubmitting && <Loading />}
          </Button>
        </div>
      </form>
    </div>
  )
}
