import React, { useMemo, useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../shadcn/dialog'
import AddQuote from './dialogs/AddQuote/AddQuote'
import AddTopic from './dialogs/AddTopic/AddTopic'
import AddArticle from './dialogs/AddArticle/AddArticle'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTopics } from '../../redux/topics/actions'
import CreateContrastDialog from '../CreateContrastDialog/CreateContrastDialog'
import { createPosts, getAllPosts } from '../../redux/posts/actions'
import {
  allowedOnlyTo,
  doesUserHasMediaOrg,
  getAllowedPostTypes,
} from '../../utils/permissions'

type Props = {
  trigger?: React.ReactNode
  cb?: () => void
  parentTopicId?: string
  disableTopic?: boolean
  articleAllow?: boolean
  quotesAllow?: boolean
}

export default function EntryDialog({
  trigger,
  parentTopicId,
  cb,
  disableTopic = false,
  articleAllow = false,
  quotesAllow = false,
}: Props) {
  const [openEntry, setOpenEntry] = useState(false)
  const [openPost, setOpenPost] = useState(false)
  const [openQuote, setOpenQuote] = useState(false)
  const [openTopic, setOpenTopic] = useState(false)
  const [openArticle, setOpenArticle] = useState(false)

  const [type, setType] = useState('')

  const dispatch = useDispatch()

  const currentUser = useSelector((state: any) => state.login.user)

  const allowedPostTypes = getAllowedPostTypes(
    currentUser?.roles?.permissions?.postCreation,
    ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']
  )

  const userHasMediaOrg = useMemo(
    () => doesUserHasMediaOrg(currentUser),
    [currentUser]
  )

  const isArticleAllowed =
    userHasMediaOrg ||
    !!['Professional', 'Professional+', 'Citizen'].find(
      (y) => y === currentUser?.roles?.name
    )

  const postItemsAvailable =
    currentUser?.roles?.permissions?.postCreation?.length > 0

  return (
    <div>
      <Dialog open={openEntry} onOpenChange={setOpenEntry}>
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent className=" max-w-[400px] px-14 py-14 max-h-screen overflow-y-auto overflow-x-visible mx-mobile:h-[100dvh]">
          <div>
            {postItemsAvailable ? (
              <div>
                <h1 className="text-2xl font-bold">Choose Type</h1>
                <div className="h-[1px] w-[60px] bg-black mt-[8px]" />
              </div>
            ) : (
              <div className="text-[14px] text-border text-center">
                Not enough permission to create a post
              </div>
            )}
            <div className="flex flex-col gap-2 mt-4">
              {allowedPostTypes.map((x) => {
                return (
                  <div
                    key={x}
                    className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                    onClick={() => {
                      // setPostDialogOpen(true)
                      setType(x)
                      setOpenPost(true)
                      setOpenEntry(false)
                    }}
                  >
                    {x}
                  </div>
                )
              })}
            </div>

            {(articleAllow || disableTopic || quotesAllow) && (
              <>
                <h1 className="text-2xl font-bold mt-14">Or</h1>
                <div className="h-[1px] w-[60px] bg-black mt-[8px]" />
              </>
            )}
            <div className="flex flex-col gap-2 mt-4">
              {quotesAllow && (
                <div
                  className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                  onClick={() => {
                    setOpenQuote(true)
                    setOpenEntry(false)
                  }}
                >
                  Add quote
                </div>
              )}

              {articleAllow && (
                <div
                  className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                  onClick={() => {
                    setOpenArticle(true)
                    setOpenEntry(false)
                  }}
                >
                  Add article
                </div>
              )}

              {disableTopic && (
                <div
                  className="text-xl cursor-pointer hover:bg-line rounded-lg transition-all px-2 py-[2px] -ml-2"
                  onClick={() => {
                    setOpenTopic(true)
                    setOpenEntry(false)
                  }}
                >
                  Create topic
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <CreateContrastDialog
        parentTopicId={parentTopicId}
        isOpen={openPost}
        setIsOpen={setOpenPost}
        postTypes={allowedPostTypes}
        onClickPost={async (values) => {
          // console.log({ values })
          // return

          createPosts(
            values.topicId,
            values.postType,
            values.description,
            values.postImageUrl,
            // clickedPost ? clickedPost.id : null,
            undefined,
            values.organizationId,
            values.locationObj?.id,
            values.locationObj?.name,
            values.locationObj?.geojson,
            values.locationObj?.address,
            values.locationObj?.addresstype,
            values.locationObj?.lat,
            values.locationObj?.lon,
            values.location,
            cb,
            values.video_url
          )(dispatch)
        }}
        dialogType="post"
        defaultType={type}
      />
      <AddQuote
        cb={cb}
        setOpen={setOpenQuote}
        open={openQuote}
        topicId={parentTopicId}
      />
      <AddTopic cb={cb} setOpen={setOpenTopic} open={openTopic} />
      <AddArticle
        cb={cb}
        setOpen={setOpenArticle}
        open={openArticle}
        parentTopicId={parentTopicId}
      />
    </div>
  )
}
