import authAxios from '../utils/authAxios'

export async function getLocationDetailsForEdit(address: string | undefined) {
  const res = await authAxios.post(`/v2/location/getLocationDetailsForEdit`, {
    address,
  })

  return res.data
}

export async function editLocationDetails(
  locationDetails: any,
  moderators: string[]
) {
  const res = await authAxios.post(`/v2/location/editLocationDetails`, {
    locationDetails,
    moderators,
  })

  return res.data
}

export async function isLocationId(locationId: string | undefined) {
  const res = await authAxios.post(`/v2/location/isLocationId`, {
    locationId,
  })

  return res.data
}
