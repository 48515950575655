import React from 'react'
import { cn } from '../utils/cn'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <button
        className={cn(
          'py-1 px-5 min-w-[70px] min-h-[29px] bg-line transition-all rounded-full text-[14px] font-bold text-black flex justify-center items-center',
          {
            'text-border cursor-not-allowed': props.disabled,
            'hover:bg-line/80': !props.disabled,
          },
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)

export default Button
