import React, { useCallback } from 'react'
import locationIcon from '../../assets/icons/location-white.svg'
import calenderWhite from '../../assets/icons/white-calender.svg'
import man from '../../assets/icons/man-white.svg'
import linkWhite from '../../assets/icons/link-white.svg'
import VerifiedIconSVG from '../../assets/icons/verified-blue.svg'
import PublicIconSVG from '../../assets/icons/public-yellow.svg'
import { followUsers } from '../../components-v2/Post/services'
import { getPostsOfALocalTopic, getPostsOfATopic } from '../../services/post'
import { getUserDetails } from '../../services/user'
import { cn } from '../../utils/cn'
type Props = {
  description?: string
  location?: string
  name?: string
  role?: string
  profile?: string
  createdAt?: Date
  profileLink?: string
  type?: string
  followingUser?: (userId: any) => void
  followingCount?: string
  isFollowing?: boolean
  isPublic?: boolean
  isVerified?: boolean
  userId?: string
  cd?: () => void
}
const DescriptionCardForScreens = ({
  name,
  location,
  description,
  role,
  profile,
  createdAt,
  profileLink = '',
  type,
  followingUser,
  followingCount,
  isFollowing,
  isPublic,
  isVerified,
  userId,
  cd,
}: Props) => {
  // @ts-ignore
  const d = new Date(createdAt)

  function onClickFollowUser(userId: any) {
    followUsers(userId).then(() => {
      if (cd) {
        cd()
      }
    })
  }
  return (
    <>
      <div
        className={cn(
          'flex flex-col px-7 py-7 mx-desktop:px-3 mx-desktop:py-3',
          type === 'user' ? 'bg-[#303655]' : 'bg-[#C08326]'
        )}
      >
        <div className="flex items-center justify-between">
          <div>
            <div className={'flex'}>
              <p className="text-sm font-semibold leading-6 text-[#FBFBFD]">
                {name}
              </p>
              {isVerified && (
                <div className={'ml-3 mt-[4px]'}>
                  <img
                    src={VerifiedIconSVG}
                    className="h-[15px] w-[15px]"
                    title="Verified"
                  />
                </div>
              )}

              {isPublic && (
                <div className={'ml-0.5 mt-[4px]'}>
                  <img
                    src={PublicIconSVG}
                    className="h-[15px] w-[15px]"
                    title="Public"
                  />
                </div>
              )}

              <>
                {type === 'user' &&
                  (isFollowing ? (
                    <span
                      className={
                        'ml-3 text-sm font-semibold leading-6 text-gray-400 cursor-pointer'
                      }
                      onClick={(event: any) => {
                        event.stopPropagation()
                        onClickFollowUser(userId)
                      }}
                    >
                      ✓ Following
                    </span>
                  ) : (
                    <span
                      className={
                        'ml-3 text-sm font-semibold leading-6 text-gray-400 cursor-pointer'
                      }
                      onClick={(event: any) => {
                        event.stopPropagation()
                        onClickFollowUser(userId)
                      }}
                    >
                      + Follow
                    </span>
                  ))}
              </>
            </div>
            <p
              className="text-[14px] text-white"
              style={{ fontFamily: 'Inter' }}
            >
              {role}
            </p>
          </div>
          <div>
            {profile && (
              <img
                onClick={(event) => {
                  event.stopPropagation()
                }}
                src={profile}
                className="mr-2 h-[40px] w-[40px] rounded-full object-cover border"
              />
            )}
          </div>

          {/*{topicId && <TopicFollowButton topicId={topicId} />}*/}
        </div>
        {/*<p className="text-[14px] mt-[17px]">*/}
        {/*  Location: <span className="text-[#2443B2]">{location}</span>*/}
        {/*</p>*/}

        <p className="mt-5 text-[14px] font-normal leading-4 text-[#FBFBFD] whitespace-pre-line">
          {description}
        </p>

        {profileLink && (
          <div className={'mt-5 flex'}>
            <img
              onClick={(event) => {
                event.stopPropagation()
                // if (navigateToProfile) {
                //   navigateToProfile()
                // }
              }}
              src={linkWhite}
              className="h-[16px] w-[16px] object-cover"
            />
            <span
              className="ml-4 text-[14px] text-[#FBFBFD]"
              style={{ fontFamily: 'Inter' }}
            >
              {profileLink}
            </span>
          </div>
        )}

        {location && (
          <div className={!profileLink ? 'mt-5 flex' : 'mt-2 flex'}>
            <img
              onClick={(event) => {
                event.stopPropagation()
                // if (navigateToProfile) {
                //   navigateToProfile()
                // }
              }}
              src={locationIcon}
              className="h-[15px] w-[15px] object-cover"
            />
            <span
              className="ml-4 text-[14px] text-[#FBFBFD]"
              style={{ fontFamily: 'Inter' }}
            >
              {location}
            </span>
          </div>
        )}

        <div className={'mt-2 flex'}>
          <img
            onClick={(event) => {
              event.stopPropagation()
              // if (navigateToProfile) {
              //   navigateToProfile()
              // }
            }}
            src={calenderWhite}
            className="h-[15px] w-[15px] object-cover"
          />
          <span
            className="ml-4 text-[14px] text-[#FBFBFD]"
            style={{ fontFamily: 'Inter' }}
          >
            Member since {d.getFullYear()}
          </span>
        </div>

        <div className={'mt-2 flex'}>
          <img
            onClick={(event) => {
              event.stopPropagation()
              // if (navigateToProfile) {
              //   navigateToProfile()
              // }
            }}
            src={man}
            className="h-[15px] w-[15px] object-cover"
          />
          <span className="ml-4 text-[14px] text-[#FBFBFD]">
            Followers : {followingCount ? followingCount : 0}
          </span>
        </div>
      </div>{' '}
    </>
  )
}

export default DescriptionCardForScreens
