import React, { useEffect, useState } from 'react'
import { Post } from '../../../types'

import ArticleCard from '../../ArticleScreen/ArticleCard'
import { getOneArticle } from '../../../services/public'

type Props = {
  post: Post | null
  topicId?: string
  onNoAuthDialogOpen: () => void
}

export default function ArticlesTab({
  post,
  topicId,
  onNoAuthDialogOpen,
}: Props) {
  const [article, setArticle] = useState<any>(null)

  useEffect(() => {
    if (post?.articleId) {
      //fetch the article
      getOneArticle(post?.articleId).then((data) => {
        // console.log({ data })
        setArticle(data)
      })
    }
  }, [post?.articleId])

  return (
    <div>
      {article && (
        <ArticleCard
          className="mt-3 max-w-[434px]"
          title={article.title}
          description={article?.description}
          iconSRC={article.favicon}
          imageSRC={article.imageUrl}
          name={article.domainName}
          postCount={article?.posts?.length}
          linkUrl={article.url}
          href={`/topic/${topicId}/article/${article.id}`}
          onNoAuthCallback={onNoAuthDialogOpen}
        />
      )}
    </div>
  )
}
