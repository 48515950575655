import Spacer from '../../components/Spacer'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import './index.css'
import { connect } from 'react-redux'
import TrustFeed from '../../components/TrustFeed'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  trustFeedContrasts,
  getTrustFeedChanges,
  trustFeedSeen,
} from '../../redux/trustFeed/action'
import {
  alarmTogglePost,
  alarmToggleTrustFeedChanges,
  alarmToggleTrustFeedContrasts,
} from '../../redux/posts/actions'
import { useNavigate } from 'react-router-dom'
import AnalyticsTrackerWrapper from '../../containers/AnalyticsTrackerWrapper'
import Tabs from '../../components-v2/Tabs'
import TrustFeedContrasts from './TrustFeedContrasts'
import PostCard from '../../components-v2/Post/PostCard'
import { parsePostObjToProps } from '../../components-v2/Post/helpers'
import ContrastSVG from '../../assets/icons/contrast-lighter.svg'

import { alarmToggle, upvoteToggle } from '../../components-v2/Post/services'
import userEvent from '@testing-library/user-event'
import TrustFeedChangesCard from './TrustFeedChangesCard'
import isToday from '../../utils/trustFeed'
import Loading from '../Loading'
import {useInfiniteQuery} from "@tanstack/react-query";
import {getNextPageParam, getTopicPosts} from "../../containers/TopicScreen/helpers";
import {useInView} from "react-intersection-observer";
import {getTrustFeedChangesPosts, getTrustFeedContrastsPosts} from "./helpers";

const TrustFeedScreen = ({
  getTrustFeedChanges,
  trustFeedChanges,
  userId,
  trustFeedContrasts,
  trustFeedContrastsPosts,
  alarmTogglePost,
  alarmToggleTrustFeedChanges,
  alarmToggleTrustFeedContrasts,
  trustFeedSeen,
  isTrustFeedAutoLoad,
  isTrustFeedSeen,
  addPostsToRedux,
  userLogin,
  trustFeedChangesLoading,
  trustFeedContrastsLoading,
}: any) => {
  const navigate = useNavigate()


  // useEffect(() => {
  //   if (isTrustFeedSeen) {
  //     return
  //   } else {
  //   }
  // }, [isTrustFeedSeen])

  const location = useLocation()

  // useEffect(() => {
  //   const unlisten = () => {
  //     yourFunction()
  //   }
  //
  //   return () => {
  //     unlisten()
  //   }
  // }, [location]) // Re-run effect when location changes
  //
  // const yourFunction = () => {
  //   trustFeedSeen()
  // }

  const {
    data: tfChangesData,
    fetchNextPage:fetchNextPagetfChanges,
    isFetching,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: [
      'tfChanges',
    ],
    queryFn: getTrustFeedChangesPosts,
    initialPageParam: 1,
    getNextPageParam: getNextPageParam,
  })

  const {
    ref: loadMoreRefTfChanges,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      if (inView) {
        fetchNextPagetfChanges()
      }
    },
  })

  let tfChanges = []

  for (const page of tfChangesData?.pages || []) {
    for (const item of page?.trustFeed || []) {
      tfChanges.push(item)
    }
  }

  const {
    data: tfContrastsData,
    fetchNextPage:fetchNextPageTfContrasts,
    isFetching:isFetchingTfContrasts,
    isLoading:isLoadingTfContrasts,
    refetch:refetchTfContrasts,
  } = useInfiniteQuery({
    queryKey: [
      'tfContrasts',
    ],
    queryFn: getTrustFeedContrastsPosts,
    initialPageParam: 1,
    getNextPageParam: getNextPageParam,
  })

  const {
    ref: loadMoreRefTfContrasts,
    inView:ivViewTfContrasts,
    entry:entryTfContrast,
  } = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      if (inView) {
        fetchNextPageTfContrasts()
      }
    },
  })

  let tfContrasts = []

  for (const page of tfContrastsData?.pages || []) {
    for (const item of page?.trustFeed || []) {
      tfContrasts.push(item)
    }
  }

  const [currentTab, setCurrentTab] = useState(
      !isTrustFeedSeen ? tfChanges?.length > 0
          ? 'changes'
          : tfContrasts?.length > 0
              ? 'contrasts'
              : 'changes' : 'changes'
  )


  const [lastInteraction, setLastInteraction] = useState<string | null>(null);

  useEffect(() => {
    // Define the interaction handler with a general 'Event' type
    const handleInteraction = (event: Event) => {
      if (event instanceof MouseEvent || event instanceof KeyboardEvent) {
        trustFeedSeen()
      }
    };

    // Add event listeners for specific user interactions
    window.addEventListener('click', handleInteraction);
    window.addEventListener('scroll', handleInteraction);
    window.addEventListener('keydown', handleInteraction);

    // Cleanup event listeners when the component unmounts
    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
    };
  }, []);



  return (
      <AnalyticsTrackerWrapper>
        <div>
          <div className="h-11 mb-2">
            <Tabs
                className="h-11 mx-mobile:h-fit mx-mobile:py-2 items-center fixed border-b bg-white z-30"
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                tabs={[
                  {
                    value: 'changes',
                    label: 'Changes',
                  },
                  {
                    value: 'contrasts',
                    label: 'Contrasts',
                  },
                ]}
            />
          </div>
          {currentTab === 'changes' ? (
              <>
                <>
                  {tfChanges.length > 0 ? tfChanges?.map((trustFeed: any) => {
                        return (
                            <TrustFeedChangesCard
                                trustFeed={trustFeed}
                                addPostsToRedux={addPostsToRedux}
                            />
                        )
                      }) :

                      <div className="mt-10 text-center text-[14px] text-border">
                        No Posts
                      </div>
                  }
                  <div className="mt-5 flex justify-center items-center">{isFetching && <Loading/>}</div>
                  <div ref={loadMoreRefTfChanges}></div>
                </>
              </>
          ) : (
              <>
                {tfContrasts?.length > 0 ? <TrustFeedContrasts
                    trustFeedContrasts={tfContrasts}
                    addPostsToRedux={addPostsToRedux}/> : <div className="mt-10 text-center text-[14px] text-border">
                  No Posts
                </div>}
                <div className="mt-5 flex justify-center items-center">{isFetchingTfContrasts && <Loading/>}</div>
                <div ref={loadMoreRefTfContrasts}></div>
              </>
          )}
          <Spacer height={40}/>
        </div>

        { !isTrustFeedSeen && <div
            className={'bottom-nav-bar bottom-nav-bar-text'}
            onClick={() => {
              navigate('/')
              trustFeedSeen()
            }}
        >
          Skip & go to home feed
        </div>}
      </AnalyticsTrackerWrapper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    trustFeedChanges: state.trustFeed.trustFeedChanges,
    userId: state?.login?.user?.id,
    userLogin: state?.login?.user?.lastLogin,

    //trust feed contrasts
    trustFeedContrastsPosts: state.trustFeed.trustFeedContrasts,
    isTrustFeedAutoLoad: state.trustFeed.isTrustFeedAutoLoad,
    isTrustFeedSeen: state.trustFeed.isTrustFeedSeen,
    trustFeedChangesLoading: state.trustFeed.trustFeedChangesLoading,
    trustFeedContrastsLoading: state.trustFeed.trustFeedContrastsLoading,
    // userLogin: state.login.user.lastLogin,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    alarmToggleTrustFeedChanges: (postId: any) => {
      dispatch(alarmToggleTrustFeedChanges(postId))
    },
    trustFeedContrasts: () => {
      dispatch(trustFeedContrasts())
    },
    alarmTogglePost: (postId: any) => {
      dispatch(alarmTogglePost(postId))
    },
    alarmToggleTrustFeedContrasts: (postId: any) => {
      dispatch(alarmToggleTrustFeedContrasts(postId))
    },
    trustFeedSeen: () => {
      dispatch(trustFeedSeen())
    },
    addPostsToRedux: (post: any) => {
      dispatch(addPostsToRedux(post))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrustFeedScreen)
