import { getMe, login } from '../../redux/login/actions'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.css'

import checkLogo from '../../assets/icons/check-logo.svg'

import InputText from '../../components/InputText'
import OnBoardButton from '../../components/OnBoardButton'
import { toast } from 'sonner'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { notEmpty } from '../../utils/formHelpers'
import { Input } from '../../components-v2/shadcn/input'
import Button from '../../components-v2/Button'
import axios from 'axios'
import Loading from '../../components-v2/Loading'
import { isAuthenticated } from '../../utils/auth'

const schema = z.object({
  email: z.string().email().pipe(notEmpty),
  password: z.string().pipe(notEmpty),
})

type SchemaType = z.infer<typeof schema>

const Login = ({ login, getMe, userLoading, user, userError }: any) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const redirect = searchParams.get('redirect')

  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/')
    }
  }, [isAuthenticated])

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {},
  })

  const [formError, setFormError] = useState('')

  // console.log('🚀 ~ Login ~ errors:', errors)
  // const [email, setEmail] = useState('')
  // const [buttonDisable, setButtonDisable] = useState(true)

  // const [emailError, setEmailError] = useState('')

  // const [password, setPassword] = useState()

  // useEffect(() => {
  //   if (
  //     /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
  //     password
  //   ) {
  //     setButtonDisable(false)
  //   }
  // }, [email, password])

  // const onSetEmail = (text: any) => {
  //   setEmail(text)
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
  //     setEmailError('')
  //   } else {
  //     setEmailError('Email is not valid')
  //   }
  // }

  // useEffect(() => {
  //   userError && !userLoading && toast.error(userError)
  // }, [userError, userLoading])

  // useEffect(() => {
  //   if (user) {
  //     toast.success('Success!')
  //     navigate('/')
  //   }
  // }, [navigate, user])

  // const onClickButton = () => {
  //   login(email, password)
  // }

  return (
    <div className="main-div-sign-up h-screen">
      {/*main div*/}
      <div className={'flex justify-center'}>
        {/*check logo div*/}
        <img className={'image-size-sign-up'} src={checkLogo} alt="" />
        {/*check logo*/}
      </div>

      <div className={'check-text-margin-top-sign-up text-center'}>
        <text className={'check-text-sign-up mt-1'}>niooze</text>
      </div>

      <form
        className="mx-auto mb-20 mt-[94px] flex max-w-[300px] flex-col text-white"
        onSubmit={handleSubmit(async (values) => {
          // console.log({ values })
          // login(values.email, values.password).then(() => {
          //   navigate('/')
          // })

          // await new Promise((res) => setTimeout(res, 3000))

          await axios
            .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
              email: values.email,
              password: values.password,
            })
            .then((res) => {
              localStorage.setItem('token', res.data.token)
              getMe()

              setTimeout(() => {
                redirect ? navigate(redirect) : navigate('/')
              }, 2000)
            })
            .catch((err) => {
              // console.log({ err })
              setFormError(err?.response?.data)
            })
        })}
      >
        <div className="font-bold">
          <p>
            Complete your <br /> login details
          </p>
        </div>

        <div className="pt-[40px]">
          <p className="mb-[6px] text-[14px] font-bold">Email address*</p>
          <Input
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            placeholder="Email"
            {...register('email')}
          />
          {errors?.email && (
            <p className="mt-1 text-[14px] font-bold text-red-500">
              {errors?.email?.message}
            </p>
          )}
        </div>

        <div className="pt-[40px]">
          <p className="mb-[6px] text-[14px] font-bold">Password*</p>
          <Input
            type="password"
            className="h-[29px] border-2 border-line bg-transparent text-white placeholder:text-[11px] placeholder:italic placeholder:text-line"
            placeholder="Password"
            {...register('password')}
          />
          {errors?.password && (
            <p className="mt-1 text-[14px] font-bold text-red-500">
              {errors?.password?.message}
            </p>
          )}
        </div>

        <Link to={'/forgot-password'} className="mt-2 self-end text-[14px]">
          Forgot the password?
        </Link>

        <div className="mt-[90px] flex flex-col items-center">
          {formError && (
            <p className="mb-1 text-[14px] font-bold text-red-500">
              {formError}
            </p>
          )}
          <Button
            disabled={isSubmitting}
            type="submit"
            className="flex w-full items-center justify-center gap-2 text-black transition-all"
          >
            Continue {isSubmitting && <Loading />}
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.login.user,
    userLoading: state.login.userLoading,
    userError: state.login.userLoginError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (name: string = '', password: string = '') => {
      return dispatch(login(name, password))
    },
    getMe: () => {
      dispatch(getMe())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
