import { ReactNode, useEffect, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '../shadcn/dialog'
import { Input } from '../shadcn/input'
import Button from '../Button'
import { getEmbedUrl } from './helper'

type DialogProps = {
  //   open: boolean
  //   setOpen: (isOpen: boolean) => void
  trigger?: ReactNode
  onSet: (url: string) => void
}

export default function VideoEmbedDialog(props: DialogProps) {
  const { trigger, onSet } = props
  const [open, setOpen] = useState(false)
  const [inputUrl, setInputUrl] = useState<string>('')
  const [embedUrl, setEmbedUrl] = useState<string | null>(null)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent overlayLight className="">
        <div className="flex flex-col">
          <p className="text-[15px] font-semibold">Paste the video link here</p>
          <Input
            value={inputUrl}
            onChange={(e) => {
              const url = e.target?.value
              setInputUrl(url)
              const embedUrl = getEmbedUrl(url)
              setEmbedUrl(embedUrl)
            }}
            className="mt-1 bg-line placeholder:italic placeholder:text-border"
            placeholder="https://www.youtube.com/watch?v=T6-PI4UM"
          />
          {embedUrl && (
            <iframe
              className="w-full aspect-video rounded-md mt-3"
              src={embedUrl}
              title="Embedded Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          {!embedUrl && inputUrl.length > 5 && (
            <p className="text-sm text-border mt-1">
              This video provider is not supported.
            </p>
          )}
          <Button
            disabled={!embedUrl}
            onClick={() => {
              if (embedUrl) {
                onSet(embedUrl)
                setOpen(false)
              }
            }}
            className="self-end mt-5"
          >
            Set
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
