import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import './index.css'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

import { createOrganizations } from '../../redux/organizations/actions'

import InputText from '../../components/InputText'
import OnBoardButton from '../../components/OnBoardButton'
import camera from '../../assets/icons/camera.svg'

//firbease
import { storage } from '../../firebase/firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import CreateOrganizationNoticeModal from '../../components/CreateOrganizationNoticeModal'

const CreateOrganization = ({
  createOrganizations,
  userId,
  createOrganizationLoading,
}: any) => {
  const [imageURL, setImageUrl] = useState('')
  const handleImage = (e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    if (URL.createObjectURL(e.target.files[0]) == null) return
    const imageRef = ref(
      storage,
      `user-images/${e?.target?.files[0]['name'] + uuidv4()}`
    )
    uploadBytes(imageRef, e.target.files[0]).then((e) => {
      getDownloadURL(e.ref).then((url) => {
        setImageUrl(url)
      })
    })
  }

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  }))

  const inputImage = useRef(document.createElement('input'))
  const [image, setImage] = useState<any | null>(null)

  const navigate = useNavigate()

  //form data begin
  const [name, setName] = useState('')
  const [orgType, setOrgType] = useState('')
  const [website, setWebSite] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [description, setDescription] = useState('')

  const [emailError, setEmailError] = useState('')
  const [buttonDisable, setButtonDisable] = useState(true)
  const [openDropdown, setOpenDropDown] = useState(false)

  const [createOrgModalDisplay, setCreateOrgModalDisplay] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [orgErrorMessage, setOrgErrorMessage] = useState('')

  const uploadImage = () => {
    inputImage.current.click()
  }

  const submitDetails = () => {
    if (!name) {
      setNameErrorMessage('Name Required')
    }
    if (!orgType) {
      setOrgErrorMessage('Organisation Required')
    }
    if (name && orgType) {
      setCreateOrgModalDisplay(!createOrgModalDisplay)
    }
  }

  useEffect(() => {
    if (name) {
      setNameErrorMessage('')
    }
    if (orgType) {
      setOrgErrorMessage('')
    }
  }, [name, orgType])

  const onSetEmail = (text: any) => {
    setEmail(text)
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text)) {
      setEmailError('')
    } else {
      setEmailError('Email is not valid')
    }
  }

  const orgTypeFunction = (e: any) => {
    setOrgType(e)
    setOpenDropDown(!openDropdown)
  }

  const submit = async () => {
    try {
      createOrganizations(
        name,
        imageURL,
        orgType,
        description,
        website,
        userId,
        email,
        phone
      )
    } catch (e: any) {
      console.log('error', e)
    } finally {
      setTimeout(() => {
        setCreateOrgModalDisplay(!createOrgModalDisplay)
        navigate('/')
      }, 2000)
    }
  }

  return (
    <div className="main-div-create-org">
      {/*main div*/}
      {createOrgModalDisplay && (
        <CreateOrganizationNoticeModal
          loading={createOrganizationLoading}
          submitCreateOrg={submit}
        />
      )}
      <div className={'mt-5 text-center'}>
        {/*header*/}
        <text
          className={'text-black text-center   font-bold text-lg flex-shrink-0'}
        >
          Set up your organization
        </text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '12px' /*marginTop:'50px'*/,
        }}
      >
        <Badge
          overlap="circular"
          style={{ cursor: 'pointer' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar alt="Remy Sharp" src={camera} onClick={uploadImage} />
          }
        >
          <Avatar
            alt="Remy Sharp"
            src={image}
            sx={{
              width: 200,
              height: 200,
              cursor: 'pointer',
              bgcolor: '#FBFBFD',
              color: '#000',
              fontSize: '11px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              border: '1px solid #9D9D9D',
            }}
            onClick={uploadImage}
          >
            <div className={'avatar-text-width-profile-screen text-center'}>
              {' '}
              Add profile picture
            </div>
          </Avatar>
        </Badge>

        <input ref={inputImage} type="file" hidden onChange={handleImage} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
          marginRight: '10px',
        }}
      >
        <div className={'middle-flex-profile'}>
          <div className={'text-left margin-top-inputs'}>
            <div>
              <InputText
                valueColor={'black'}
                textColor={'#000'}
                inputColor={'#FBFBFD'}
                name={'Name'}
                placeHolder={'Name'}
                mandatory={true}
                inputType="text"
                setValue={setName}
              />
              <text className={'text-red-600 font-semibold text-[14px]'}>
                {nameErrorMessage}
              </text>
            </div>
            <div style={{ marginTop: '23px' }}>
              <div style={{ marginBottom: '6px' }}>
                <text
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: '#000',
                  }}
                >
                  Organization type{<span style={{ fontSize: '13px' }}>*</span>}
                </text>
              </div>
              <div style={{ width: '300px' }}>
                <input
                  className={'inputList'}
                  value={orgType}
                  // onChange={(e) => {setOrgType(e.target.value)}}
                  onClick={() => {
                    setOpenDropDown(!openDropdown)
                  }}
                  type={'text'}
                  placeholder={'Select'}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    backgroundColor: '#FBFBFD',
                    borderRadius: '6px',
                    height: '29px',
                    flexShrink: 0,
                    //paddingLeft:'10px',
                    fontStyle: 'italic',
                    //paddingLeft:'12px',
                    paddingLeft: '12px', // Adjusted padding on the left
                    paddingRight: '12px', // Padding on the right
                    letterSpacing: '0.00001em',
                    zIndex: 1000,
                    borderColor: '#D9D9D9',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    color: 'black',
                  }}
                />

                <div
                  style={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: '#D9D9D9',
                    marginTop: '-2px',
                    width: '290px',
                    marginLeft: '3px',
                  }}
                >
                  {openDropdown &&
                    Organisations?.map((org: any) => (
                      <p
                        className={'on-hover-organization'}
                        style={{
                          color: '#000',
                          fontSize: '13px',
                          paddingLeft: '5px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                        }}
                        onClick={() => {
                          orgTypeFunction(org)
                        }}
                      >
                        {org}
                      </p>
                    ))}
                </div>
                <text className={'text-red-600 font-semibold text-[14px]'}>
                  {orgErrorMessage}
                </text>
              </div>
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                valueColor={'black'}
                textColor={'#000'}
                inputColor={'#FBFBFD'}
                name={'Website'}
                placeHolder={'URL'}
                mandatory={false}
                inputType="text"
                setValue={setWebSite}
              />
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                valueColor={'black'}
                textColor={'#000'}
                inputColor={'#FBFBFD'}
                name={'Email'}
                placeHolder={'Email'}
                mandatory={false}
                inputType="text"
                setValue={onSetEmail}
              />
              <p style={{ color: 'red', fontSize: '12px' }}>{emailError}</p>
            </div>

            <div style={{ marginTop: '23px' }}>
              <InputText
                valueColor={'black'}
                textColor={'#000'}
                inputColor={'#FBFBFD'}
                name={'Phone number'}
                placeHolder={'Phone'}
                mandatory={false}
                inputType="text"
                setValue={setPhone}
              />
            </div>
            <div style={{ marginTop: '23px' }}>
              <InputText
                valueColor={'black'}
                textColor={'#000'}
                inputColor={'#FBFBFD'}
                value={description}
                name={'Description'}
                placeHolder={'Optional'}
                mandatory={false}
                inputType="textarea"
                setValue={setDescription}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: '29px',
              cursor: 'pointer',
              marginBottom: '50px',
            }}
          >
            <OnBoardButton
              width={'300px'}
              loading={createOrganizationLoading}
              buttonName={'Create'}
              disabled={false}
              onClick={submitDetails}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Organisations = [
  //list of organisations
  'Government',
  'Political organization',
  'International organization',
  'Non-governmental organization',
  'Educational institution',
  'Non-profit media',
  'For-profit media',
  'Government linked media',
  'Private company/org',
  'Government linked company/org',
]

const mapStateToProps = (state: any) => {
  return {
    userId: state?.login?.user?.id,
    createOrganizationLoading: state.organization.createOrganizationLoading,
    createOrganizationError: state.organization.createOrganizationError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createOrganizations: (
      name: string,
      imageUrl: string,
      type: string,
      description: string,
      orgLink: string,
      userId: string,
      email: string,
      phone: string
    ) => {
      dispatch(
        createOrganizations(
          name,
          imageUrl,
          type,
          description,
          orgLink,
          userId,
          email,
          phone
        )
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganization)
