export const diffToReactNode = (diffs: any[]) => {
  return (
    <div className="text-[14px]">
      {diffs.map((diff: any, index: number) => {
        if (diff.removed) {
          return (
            <span
              key={index}
              className="text-red-600 line-through font-semibold"
            >
              {diff.value}
            </span>
          )
        }
        if (diff.added) {
          return (
            <span key={index} className="text-green-600 font-semibold">
              {diff.value}
            </span>
          )
        }
        return <span key={index}>{diff.value}</span>
      })}
    </div>
  )
}
