import React from 'react'
import PublicHeader from '../../components-v2/Header/PublicHeader'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { notEmpty } from '../../utils/formHelpers'
import Button from '../../components-v2/Button'
import { Textarea } from '../../components-v2/shadcn/textarea'
import { Input } from '../../components-v2/shadcn/input'
import { sendForgotEmail } from './helpers'
import { toast } from 'sonner'

type Props = {}

const schema = z.object({
  email: z.string().email().pipe(notEmpty),
})

type SchemaType = z.infer<typeof schema>

export default function ForgotPasssordScreen({}: Props) {
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })
  return (
    <div>
      <PublicHeader />
      <form className="flex flex-col mt-5 pb-5 max-w-[450px] mx-auto px-5">
        <h1 className="text-2xl font-bold mt-5">Forgot Password</h1>
        <p className="text-sm mt-1">
          If you've forgotten your password, don't worry! Enter your email
          address below, and we'll send you instructions to reset your password.
        </p>
        <div className="mt-6">
          <label className="text-[14px] font-bold">Email*</label>
          <Input
            className=" border-line placeholder:text-line  placeholder:italic mt-1 text-sm"
            placeholder="Email"
            {...register('email')}
          />
          {errors?.email && (
            <p className="text-[14px] text-red-500 font-semibold">
              {errors?.email.message}
            </p>
          )}
        </div>

        <div className="flex items-center justify-end mt-5">
          <Button
            disabled={isSubmitting}
            onClick={handleSubmit(async (value) => {
              // console.log('🚀 ~ onClick={handleSubmit ~ value:', value)
              return sendForgotEmail(value.email)
                .then((data) => {
                  toast.success(
                    'If your account exists, a reset link is on the way'
                  )
                })
                .catch((err) => {
                  console.log(err)
                  toast.error(
                    err?.response?.data?.message || 'Something went wrong'
                  )
                })
            })}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
}
