import React, { useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '../../shadcn/popover'
import { ChevronDown } from 'lucide-react'
import { orderFilterOptions, timeFilterOptions } from '../helpers'
import { cn } from '../../../utils/cn'
import DropDownSVG from '../../../assets/icons/drop-down.svg'

type Props = {
  setFilterOrder: (type: string) => void
  filterOrder: string

  setFilterTime: (type: string) => void
  filterTime: string
}

export default function TimeFilters({
  filterOrder,
  filterTime,
  setFilterOrder,
  setFilterTime,
}: Props) {
  const [timeFilterOpen, setTimeFilterOpen] = useState(false)
  const [orderFilterOpen, setOrderFilterOpen] = useState(false)

  return (
    <div className="flex flex-col items-center justify-center w-[200px] p-2 rounded-lg relative">
      {/* <div className="absolute top-0 left-0 bg-white blur-md" /> */}
      <Popover modal open={timeFilterOpen} onOpenChange={setTimeFilterOpen}>
        <PopoverTrigger className="relative">
          <div className="absolute top-0 left-0 blur-sm w-full h-full bg-white -z-10" />
          <div className="text-[14px] italic flex items-center gap-1 px-3">
            {timeFilterOptions.find((x) => x.value === filterTime)?.label ||
              'Select'}
            {/* <ChevronDown size={18} className="" /> */}

            <img src={DropDownSVG} className="w-[18px] h-[18px]" />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-fit py-2 px-1 flex flex-col gap-1">
          {timeFilterOptions.map((x) => {
            return (
              <div
                key={x.value}
                className={cn(
                  'hover:bg-[#d5e7c4] px-2 transition-all rounded-md cursor-pointer',
                  {
                    'font-semibold': filterTime === x.value,
                  }
                )}
                onClick={() => {
                  setFilterTime(x.value)
                  setTimeFilterOpen(false)
                }}
              >
                {x.label}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>

      <Popover modal open={orderFilterOpen} onOpenChange={setOrderFilterOpen}>
        <PopoverTrigger className="relative  flex">
          <div className="absolute top-0 left-0 blur-sm w-full h-full bg-white -z-10" />
          <div className="text-[14px] italic flex items-center gap-1 px-3 whitespace-nowrap">
            {orderFilterOptions.find((x) => x.value === filterOrder)?.label ||
              'Select'}
            {/* <ChevronDown size={18} className="" /> */}
            <img
              src={DropDownSVG}
              className="w-[18px] h-[18px] flex-shrink-0"
            />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-fit py-2 px-1 flex flex-col gap-1">
          {orderFilterOptions.map((x) => {
            return (
              <div
                key={x.value}
                className={cn(
                  'hover:bg-[#d5e7c4] px-2 transition-all rounded-md cursor-pointer',
                  {
                    'font-semibold': filterOrder === x.value,
                  }
                )}
                onClick={() => {
                  setFilterOrder(x.value)
                  setOrderFilterOpen(false)
                }}
              >
                {x.label}
              </div>
            )
          })}
        </PopoverContent>
      </Popover>
    </div>
  )
}
