import React from 'react'
import { cn } from '../../utils/cn'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
  title: string
  iconSRC: string
  name: string
  description: string
  imageSRC?: string
  postCount?: number
  className?: string
  href?: string
  linkUrl?: string
  disableLable?: boolean
  onNoAuthCallback?: () => void
}

export default function ArticleCard({
  description,
  iconSRC,
  name,
  title,
  postCount,
  imageSRC,
  className,
  href,
  linkUrl,
  disableLable,
  onNoAuthCallback,
}: Props) {
  const navigate = useNavigate()

  return (
    <div
      className={cn(
        'max-w-[468px] w-full',
        // {
        //   'cursor-pointer': href,
        // },
        className
      )}
    >
      {!disableLable && (
        <p className="ml-[12px] font-semibold text-[14px] text-text pb-[2px] pt-[2px] uppercase">
          Article
        </p>
      )}
      <div
        onClick={() => {
          if (onNoAuthCallback) {
            onNoAuthCallback()
          } else {
            href && navigate(href)
          }
        }}
        // {...linkProps}
        // to={href ? href : null}
      >
        {imageSRC ? (
          <img
            src={imageSRC}
            alt={title}
            className={cn('w-full h-60 object-cover', {
              'cursor-pointer': href,
            })}
          />
        ) : (
          <div className="w-full h-60 bg-primary">
            <div className="text-[11px] font-semibold text-border w-full p-2">
              No image detected
            </div>
          </div>
        )}

        <div className="flex justify-between items-center mt-[10px]">
          <div className="flex gap-[6px] items-center ">
            {iconSRC ? (
              <img
                src={iconSRC}
                className={cn(
                  'w-[35px] h-[35px] object-cover rounded-full border'
                )}
              />
            ) : (
              <div className="w-[35px] h-[35px] rounded-full bg-primary text-[5px] text-border flex items-center justify-center">
                No Image
              </div>
            )}

            <div>
              <p className="text-[14px] font-semibold text-border ">{name}</p>
              {/* <p className="text-[9px] text-border">{description}</p> */}
            </div>
          </div>
          {postCount && href && (
            <p className=" text-[14px] font-semibold text-border pr-[3px] underline underline-offset-4 cursor-pointer">
              Read {postCount} posts
            </p>
          )}
        </div>
        <p
          className="text-base font-semibold mt-[10px] leading-tight mb-[12px] cursor-pointer hover:underline"
          // href={linkUrl}
          // target="_blank"
          onClick={(e) => {
            e.stopPropagation()
            window.open(linkUrl, '_blank')
          }}
        >
          {title}
        </p>

        {/* {postCount && (
        <p className="text-[9px] text-border mt-[11px]">{postCount} posts</p>
      )} */}
      </div>
    </div>
  )
}
