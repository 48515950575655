import { AlertCircle, ChevronDown, Film, Video, XCircle } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import LandscapeSVG from '../../assets/icons/landscape.svg'
import { getOnePost } from '../../services/post'
import { cn } from '../../utils/cn'
import uploadImage, { formatBytes, useUpload } from '../../utils/uploadImage'
import ReasonDialog from '../Post/ReasonDialog'
import TooltipComp from '../Tooltip'
import { Dialog, DialogContent, DialogTrigger } from '../shadcn/dialog'
import { Popover, PopoverContent, PopoverTrigger } from '../shadcn/popover'
import {
  Icon,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../shadcn/select'
import { Textarea } from '../shadcn/textarea'
import TopicSelectDialogContent from './TopicSelectDialogContent'
import {
  fetchPostEditRules,
  getDescriptionPaceholder,
  getDialogTitle,
  getPostAuthorOrganizations,
} from './helper'
import { contentToJSX, stringHasLink } from '../Post/helpers'
import { Switch } from '../shadcn/switch'
import css from './styles.module.css'
import { getMyLocation, getOneTopic } from '../../services/topic'
import Button from '../Button'
import DropDownSVG from '../../assets/icons/drop-down.svg'
import { Progress } from '../shadcn/progress'
import UploadProgress from '../UploadProgress'
import Loading from '../Loading'
import LoadingOverlay from '../LoadingOverlay'
import VideoEmbedDialog from '../VideoEmbedDialog/VideoEmbedDialog'
import {isLocationId} from "../../services/location";

type Props = {
  postTypes: string[]
  trigger?: React.ReactNode
  parentPostType?: string
  parentPostId?: string
  parentTopicId?: string
  onClickPost: (data: any) => Promise<any>
  dialogType: string
  defaultType?: string

  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void

  editPostId?: string
}

// const selectOptions = ['Fact', 'Research', 'Opinion', 'Rumor', 'Humor']

export default function CreateContrastDialog(props: Props) {
  const { isOpen, setIsOpen, trigger } = props

  const [open, setOpen] = useState(false)

  let isOpenBool: boolean
  let isOpenFunc: (open: boolean) => void

  if (typeof isOpen === 'boolean') {
    isOpenBool = isOpen
  } else {
    isOpenBool = open
  }

  if (setIsOpen) {
    isOpenFunc = setIsOpen
  } else {
    isOpenFunc = setOpen
  }

  return (
    <Dialog
      open={isOpenBool}
      onOpenChange={(open: boolean) => {
        isOpenFunc(open)
      }}
    >
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        disableCloseButton
        className="max-h-screen max-w-[600px] overflow-y-auto overflow-x-visible mx-mobile:h-[100dvh]"
      >
        {/* Create View */}

        <Content {...props} setIsOpen={isOpenFunc} isOpen={isOpenBool} />
      </DialogContent>
    </Dialog>
  )
}

function Content({
  trigger,
  parentPostType,
  parentPostId,
  parentTopicId,
  postTypes,
  dialogType,
  // defaultView,
  defaultType,
  onClickPost,

  isOpen,
  setIsOpen,

  editPostId,
}: Props) {
  const [topicOpen, setTopicOpen] = useState<boolean>(false)

  const currentUser = useSelector((state: any) => state.login.user)

  const [type, setType] = useState(defaultType || 'Opinion')
  // const [view, setView] = useState(defaultView || 'select')

  // const [postImageUrl, setPostImageUrl] = useState<string | null>(null)
  const [description, setDescription] = useState('')
  const [organizationId, setOrganizationId] = useState('no-org')

  const [topic, setTopic] = useState<any | null>(null)

  const [location, setLocation] = useState<any | null>(null)

  const [videoEmbedUrl, setVideoEmbedUrl] = useState<string | null>(null)

  // console.log('🚀 ~ topic:', topic)
  // const [locationObj, setLocationObj] = useState<any | null>(null)

  const [postEditRules, setPostEditRules] = useState<any>(null)

  const [isReasonDialogOpen, setIsReasonDialogOpen] = useState(false)

  const [author, setAuthor] = useState(null)
  const [disableButton, setDisableButton] = useState(true)

  const isEdit = !!editPostId

  let user: any

  if (isEdit) {
    user = author
  } else {
    user = currentUser
  }

  useEffect(() => {
    if (defaultType) {
      setType(defaultType)
    }
  }, [defaultType])
  const {
    upload,
    progress,
    url: postImageUrl,
    setUrl: setPostImageUrl,
    totalBytes,
    transferedBytes,
  } = useUpload()

  const [isEditLoading, setIsEditLoading] = useState(false)

  //validating post creation dialog
  useEffect(() => {
    //need to have an image or description
    //need to have a topic or location(avoid comment or contrast)
    const isConORCom = parentPostType //is con or com
    const isPostValid =
      (postImageUrl || (description.length > 0 && description.length < 301)) &&
      (topic?.label || isConORCom || location?.label)

    setDisableButton(!isPostValid)
  }, [postImageUrl, description, topic, location])

  useEffect(() => {
    //load data of the post
    if (isEdit) {
      setIsEditLoading(true)
      getOnePost(editPostId).then((data) => {
        if (data?.postLocations) {
          // setLocationObj({ display_name: data?.postLocations?.address || '' })
          setLocation({ label: data?.postLocations?.address || '' })
        }

        setDescription(data?.description || '')
        setOrganizationId(data?.organisationId || 'no-org')
        setType(data?.postTypeId)
        setPostImageUrl(data?.mediaId || null)

        if (data?.topic) {
          setTopic({
            label: data?.topic?.title,
            value: data?.topic?.id,
          })
        }

        //TODO: add location edit after map desitions finalize
        // if (data?.location) {
        //   setLocationObj({
        //   })
        // }

        fetchPostEditRules(editPostId)
          .then((data) => {
            setIsEditLoading(false)
            setPostEditRules(data)
          })
          .catch(() => {
            setIsEditLoading(false)
          })

        //load author organizations
        getPostAuthorOrganizations(editPostId).then((data) => {
          setAuthor(data)
        })
      })
    }
  }, [isEdit, editPostId, setPostImageUrl])

  const [isLoading, setIsLoading] = useState(false)

  const uuidRegexLocal =
    /local\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/
  useEffect(() => {
  if(parentTopicId){
    setIsLoading(true)
    isLocationId(parentTopicId).then(data => {
      if (parentTopicId.match(uuidRegexLocal) || data) {
        getMyLocation()
            .then((data) => {
              setIsLoading(false)
              // setLocationObj({
              //   display_name: data.address,
              // })
              setLocation({
                label: data.address,
                id: parentTopicId?.match(uuidRegexLocal)?.[1],
              })
            })
            .catch(() => {
              setIsLoading(false)
            })
      } else {
        getOneTopic(parentTopicId)
            .then((data) => {
              // setValue('topicId', data.id)
              setIsLoading(false)
              setTopic({
                label: data.title,
                value: data.id,
              })
            })
            .catch(() => {
              setIsLoading(false)
            })
      }
    })
  }
  }, [parentTopicId])

  const onPostClick = (args?: any) => {
    setDisableButton(true)
    onClickPost({
      description: description,
      postType: type,
      postImageUrl: postImageUrl,
      organizationId: organizationId === 'no-org' ? null : organizationId,
      topicId: topic?.value ? topic?.value : parentTopicId,
      // locationObj: locationObj,
      location: location,
      parentTopicId: parentTopicId,
      parentPostId: parentPostId,
      video_url: videoEmbedUrl,
      ...args,
    }).finally(() => {
      setDisableButton(false)
      setIsOpen && setIsOpen(false)
    })
  }

  const isCommentOrContrast = !!['comment', 'contrast'].includes(dialogType)

  return (
    <div className="flex flex-col">
      {(isLoading || isEditLoading) && <LoadingOverlay />}
      {/* <ArrowLeft
        size={20}
        onClick={() => {
          setView('select')
        }}
      /> */}
      <div className="flex items-center">
        {postEditRules && !postEditRules?.type?.allowed && (
          <TooltipComp
            trigger={
              <AlertCircle className="-ml-5 w-4 text-red-500" size={20} />
            }
            tooltip={postEditRules?.type?.reason}
          />
        )}

        <p className="mt-[2px] text-[16px]">{isEdit ? 'Edit' : 'Create'}</p>

        {/* Post Type Select */}
        <Select
          disabled={!postEditRules?.type?.allowed && isEdit}
          value={type}
          onValueChange={(v) => {
            setType(v)
          }}
        >
          <SelectTrigger
            className="border-n w-fit border-none pl-2 text-xl font-semibold uppercase focus:ring-0 focus:ring-transparent focus:ring-offset-0"
            dropdownIcon={
              <img src={DropDownSVG} className="h-[18px] w-[18px]" />
            }
          >
            <SelectValue placeholder="Type" className="" />
          </SelectTrigger>
          <SelectContent>
            {postTypes.map((x, i) => {
              return (
                <SelectItem key={i} value={x}>
                  {x}
                </SelectItem>
              )
            })}
          </SelectContent>
        </Select>
      </div>
      <div className="mt-3 flex flex-col gap-3">
        {/* <div className="bg-line p-3 rounded-md text-sm">
          {parentPostId}
        </div> */}

        {/* Render topic/location picker */}
        {!['comment', 'contrast'].includes(dialogType) && (
          <div className="mt-2 flex w-full">
            {postEditRules && !postEditRules?.topic?.allowed && (
              <TooltipComp
                trigger={
                  <AlertCircle className="-ml-5 w-4 text-red-500" size={20} />
                }
                tooltip={postEditRules?.topic?.reason}
              />
            )}

            <Dialog open={topicOpen} onOpenChange={setTopicOpen}>
              <DialogTrigger asChild>
                <button
                  disabled={!postEditRules?.topic?.allowed && isEdit}
                  className={cn('w-full cursor-pointer text-left', {
                    'cursor-not-allowed':
                      !postEditRules?.topic?.allowed && isEdit,
                  })}
                >
                  {!topic && !location && (
                    <div className="h-[64px] rounded-md bg-line p-3 text-sm">
                      Select location or topic...
                    </div>
                  )}

                  {topic && (
                    <div className="flex items-center justify-between rounded-md bg-line p-3 text-sm">
                      <p className="flex flex-col">
                        <span className="">Topic</span>
                        <span className="font-semibold">{topic.label}</span>
                      </p>

                      <XCircle
                        size={20}
                        className="w-4 flex-shrink-0 cursor-pointer transition-all hover:text-red-500"
                        onClick={() => {
                          setTopic(null)
                        }}
                      />
                    </div>
                  )}

                  {location?.label && (
                    <div className="flex items-center justify-between rounded-md bg-line p-3 py-4 text-sm">
                      {/* <p className="">
                        <span className="text-border ">Location:</span>{' '}
                        <span>{locationObj.display_name}</span>
                      </p> */}

                      <p className="flex flex-col">
                        <span className="">Location</span>
                        <span className="font-semibold">{location?.label}</span>
                      </p>

                      <XCircle
                        size={20}
                        className="w-4 flex-shrink-0 cursor-pointer transition-all hover:text-red-500"
                        onClick={() => {
                          // setLocationObj(null)
                          setLocation(null)
                        }}
                      />
                    </div>
                  )}
                </button>
              </DialogTrigger>
              <DialogContent className="w-[550px] p-0 mx-sm:w-screen">
                <TopicSelectDialogContent
                  onSelectLocation={(value) => {
                    setLocation(value)
                    setTopic(null)
                    setTopicOpen(false)
                  }}
                  onSelectTopic={(value) => {
                    setTopic(value)
                    setLocation(null)
                    setTopicOpen(false)
                  }}
                />
              </DialogContent>
            </Dialog>
          </div>
        )}

        {/* hide this div if no topic and location */}
        <div
          className={cn('contents', {
            'invisible opacity-0':
              !topic?.value && !location && !isCommentOrContrast,
          })}
        >
          {parentPostType && !editPostId && (
            <div className="rounded-md bg-line p-3 text-sm">
              {getDialogTitle(dialogType, parentPostType)}
            </div>
          )}

          {/* Organization select */}
          <div
            className={cn('mt-1 flex', {
              'invisible opacity-0': !topic?.value && !location,
            })}
          >
            {postEditRules && !postEditRules?.channel?.allowed && (
              <TooltipComp
                trigger={
                  <AlertCircle className="-ml-5 w-4 text-red-500" size={20} />
                }
                tooltip={postEditRules?.channel?.reason}
              />
            )}

            <Select
              disabled={!postEditRules?.channel?.allowed && isEdit}
              value={organizationId}
              // defaultValue="no-org"
              onValueChange={(v) => {
                setOrganizationId(v)
              }}
            >
              <SelectTrigger
                className={cn(
                  'h-auto w-full border-0 p-[2px] [&>span]:h-fit [&>span]:min-h-[40px] [&>span]:w-full [&>svg]:hidden',
                  css.selected
                )}
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="w-full" align="start">
                <SelectItem
                  value="no-org"
                  className="flex-row p-1 [&>span:nth-child(1)]:hidden [&>span:nth-child(2)]:w-full"
                >
                  <div className="flex w-full justify-between">
                    <div className="flex flex-col items-start">
                      <p className="flex items-center gap-1 text-sm">
                        {user?.name}

                        {/* <ChevronDown
                        size={18}
                        className="invisible"
                      /> */}

                        <img
                          src={DropDownSVG}
                          className="invisible h-[18px] w-[18px]"
                        />
                      </p>
                      <div className="flex gap-1 text-[14px]">
                        <span className="text-border">{user?.roles?.name}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      <img
                        src={user?.imageUrl}
                        className="h-[35px] w-[35px] rounded-full object-cover"
                      />
                    </div>
                  </div>
                </SelectItem>
                {user?.organizationUsers?.map((x: any) => {
                  return (
                    <SelectItem
                      key={x.id}
                      value={x.id}
                      className="flex-row p-1 [&>span:nth-child(1)]:hidden [&>span:nth-child(2)]:w-full"
                    >
                      <div className="flex w-full justify-between">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center gap-1">
                            <p className="text-sm">{user.name}</p>
                            {/* <ChevronDown size={18} className="hidden" /> */}
                            <img
                              src={DropDownSVG}
                              className="invisible h-[18px] w-[18px]"
                            />
                          </div>
                          <div className="flex gap-1 text-[14px]">
                            <span className="font-bold">{x?.name} </span>
                            <span className="text-border">
                              {user?.roles?.name}
                            </span>
                          </div>
                          <span className="text-[14px] text-border">
                            {x?.type}
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <img
                            src={x?.imageUrl}
                            className="h-[35px] w-[35px] rounded-full object-cover"
                          />
                          <img
                            src={user?.imageUrl}
                            className="h-[35px] w-[35px] rounded-full object-cover"
                          />
                        </div>
                      </div>
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
          </div>

          <div className="flex">
            {postEditRules && !postEditRules?.text?.allowed && (
              <TooltipComp
                trigger={
                  <AlertCircle className="-ml-5 w-4 text-red-500" size={20} />
                }
                tooltip={postEditRules?.text?.reason}
              />
            )}

            <Textarea
              disabled={!postEditRules?.text?.allowed && isEdit}
              value={description}
              // className="bg-line"
              placeholder={getDescriptionPaceholder(type)}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              rows={8}
              className="mt-1 bg-line placeholder:italic placeholder:text-border"
            />
          </div>

          {/* {stringHasLink(description) && (
          <div className="flex items-center gap-2 -mb-1">
            <p className="text-[14px]">Link preview</p>
            <Switch
              checked={isLinksCollapsed}
              onCheckedChange={setIsLinksCollapsed}
            />
          </div>
        )} */}

          {/* {(description || postImageUrl) && (
          <div className="rounded-lg px-[12px] py-[10px] border-border border flex flex-col items-start relative">
            <div className="text-left text-[11px] w-full">
              {contentToJSX({
                content: description,
                collapsedURL: isLinksCollapsed,
              })}
            </div>
          </div>
        )} */}

          {videoEmbedUrl && (
            <div className="flex w-full flex-col gap-1">
              <XCircle
                className="cursor-pointer self-end text-border transition-all hover:text-red-500"
                size={18}
                onClick={() => {
                  setVideoEmbedUrl(null)
                }}
              />

              <iframe
                className="w-full aspect-video rounded-md"
                src={videoEmbedUrl}
                title="Embedded Video"
                allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}

          {postImageUrl && (
            <div className="flex w-full flex-col gap-1">
              <XCircle
                className="cursor-pointer self-end text-border transition-all hover:text-red-500"
                size={18}
                onClick={() => {
                  setPostImageUrl(null)
                }}
              />
              <img src={postImageUrl} className="rounded-md object-cover" />
            </div>
          )}
          {/* image progress bar */}

          {progress > 0 && (
            <UploadProgress
              progress={progress}
              transferedBytes={transferedBytes}
              totalBytes={totalBytes}
            />
          )}

          {/* Post Media */}
          <div className="flex">
            <div>
              {postEditRules && !postEditRules?.media?.allowed && (
                <TooltipComp
                  trigger={
                    <AlertCircle className="-ml-5 w-4 text-red-500" size={20} />
                  }
                  tooltip={postEditRules?.media?.reason}
                />
              )}

              {!videoEmbedUrl && (
                <label
                  className={cn(
                    'w-fit cursor-pointer rounded-md p-[3px] transition-all hover:bg-line',
                    {
                      'cursor-not-allowed hover:bg-transparent':
                        !postEditRules?.media?.allowed && isEdit,
                    }
                  )}
                >
                  <input
                    disabled={!postEditRules?.media?.allowed && isEdit}
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(event) => {
                      const image = event.target.files?.[0]
                      if (image) {
                        // uploadImage(image, 'user-images').then((imageUrl) => {
                        //   setPostImageUrl(imageUrl)
                        //   // console.log({ imageUrl })
                        // })

                        upload(image, 'user-images')

                        // uploadImage2(image, 'user-images').then((imageUrl) => {
                        //   console.log('🚀 ~ uploadImage2 ~ imageUrl:', imageUrl)
                        //   setPostImageUrl(imageUrl)
                        //   // console.log({ imageUrl })
                        // })
                      }
                    }}
                  />
                  <img src={LandscapeSVG} className="h-[16px] w-[16px]" />
                </label>
              )}
            </div>

            {!postImageUrl && (
              <VideoEmbedDialog
                trigger={
                  <div
                    className={cn(
                      'cursor-pointer rounded-md transition-all hover:bg-line h-[22px] w-[22px] flex items-center justify-center'
                      // {
                      //   'cursor-not-allowed hover:bg-transparent':
                      //     !postEditRules?.media?.allowed && isEdit,
                      // }
                    )}
                  >
                    <Film size={18} className="text-gray-600" />
                  </div>
                }
                onSet={setVideoEmbedUrl}
              />
            )}

            <div className={'ml-auto'}>
              <p
                className={`mt-0.5 text-right   text-[14px] font-normal leading-[13.31px] ${
                  description && description.length > 300 ? 'text-red-600' : ''
                }`}
              >
                <span>{description?.length}</span>
                <span>/300</span>
              </p>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="mt-10 flex items-center justify-between">
          <Button
            // className="py-1 px-5 min-w-[116px] bg-line hover:bg-line/80 transition-all rounded-md text-sm"
            onClick={() => {
              setIsOpen && setIsOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            // className="py-1 px-5 min-w-[116px] bg-line hover:bg-line/80 transition-all rounded-md text-sm"
            disabled={disableButton}
            onClick={() => {
              if (!isEdit) {
                onPostClick()
              } else {
                setIsReasonDialogOpen(true)
              }
            }}
          >
            {isEdit ? 'Edit' : 'Post'}
          </Button>
        </div>
      </div>

      <ReasonDialog
        isOpen={isReasonDialogOpen}
        setIsOpen={setIsReasonDialogOpen}
        description="You just edited a post. If you want, you can explain here why you did this."
        onClickFinish={(reason) => {
          console.log({ reason })
          onPostClick({
            reason: reason,
          })
        }}
      />
    </div>
  )
}
