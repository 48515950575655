import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPostsOfAThread } from './helpers'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'

type Props = {}

export default function ThreadPage({}: Props) {
  const params = useParams()
  const threadId = params?.threadId

  const [posts, setPosts] = useState([])
  console.log('🚀 ~ ThreadPage ~ posts:', posts)

  const pageLoad = useCallback(() => {
    if (threadId) {
      getPostsOfAThread(threadId).then((data) => {
        setPosts(data)
      })
    }
  }, [threadId])

  useEffect(() => {
    if (threadId) {
      getPostsOfAThread(threadId).then((data) => {
        setPosts(data)
      })
    }
  }, [threadId])

  return (
    <div>
      <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
        {/* Left div */}
        <div
          className="min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        ></div>

        {/* Middle div */}
        <div
          className="flex min-h-screen flex-col items-center border-r border-line mx-desktop:border-r-0 mx-mobile:px-5"
          style={{
            flex: '0 0 40%',
          }}
        >
          <div className="mb-16 mt-6 flex w-full flex-col gap-6">
            {posts.map((comment: any, i: number) => {
              return (
                <PostWithContrast post={comment} key={i} pageLoad={pageLoad} />
              )
            })}
          </div>
        </div>

        {/* Right div */}
        <div
          className="relative min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        ></div>
      </div>
    </div>
  )
}
